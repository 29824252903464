import { useUsers } from 'hooks';

export type RestPageProps = {
  title: string;
};

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_BASE_API_URL,
  REACT_APP_SCOPES,
  REACT_DEFAULT_SCOPES,
} = process.env;
/**
 *  @deprecated Demo page. Wiill be deleted.
 */
export function RestPage({ title }: RestPageProps) {
  const {
    users,
    isLoadingUsers,
    isErrorUsers,
    isSuccessUsers,
    getErrorsUsers,
    reqUsers,
  } = useUsers({
    getSilently: true,
  });

  return (
    <>
      <h2>{title}</h2>
      <h3>Users State:</h3>
      <p>{JSON.stringify(users())}</p>
      <h3>isLoadingUsers State:</h3>
      <p>{isLoadingUsers() ? 'true' : 'false'}</p>
      <h3>isErrorUsers State:</h3>
      <p>{isErrorUsers() ? 'true' : 'false'}</p>
      <h3>isSuccessUsers State:</h3>
      <p>{isSuccessUsers() ? 'true' : 'false'}</p>
      <h3>getErrorsUsers State:</h3>
      <p>
        {getErrorsUsers().map(
          (error) => `${error.code}: ${error.message} ${error.field}`,
        )}
      </p>
      <h2>Actions</h2>
      <button
        onClick={async () => await reqUsers({ method: 'GET', url: '/users' })}
      >
        GET USERS
      </button>
      <h2>ENV</h2>
      <p>REACT_APP_AUTH0_CLIENT_ID:{REACT_APP_AUTH0_CLIENT_ID}</p>
      <p>REACT_APP_AUTH0_DOMAIN:{REACT_APP_AUTH0_DOMAIN}</p>
      <p>REACT_APP_BASE_API_URL:{REACT_APP_BASE_API_URL}</p>
      <p>REACT_APP_SCOPES:{REACT_APP_SCOPES}</p>
      <p>:REACT_DEFAULT_SCOPES:{REACT_DEFAULT_SCOPES}</p>
    </>
  );
}
