import {
  AlertBox,
  Loading,
  Card,
  Button,
  Stack,
  SearchBar,
} from '@guider-global/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { useAppContext } from 'context';
import { useAdminUserProfiles } from 'hooks';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageView } from 'views';

export function UsersSearchPage() {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const navigate = useNavigate();
  const { organizationSlug, isRootDomain } = useAppContext();

  const [searchBoxText, setSearchBoxText] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  const { isLoadingAdminUserProfiles, reqAdminUserProfiles } =
    useAdminUserProfiles({
      getSilently: false,
    });

  const lookupAdminUserProfile = useCallback(async () => {
    try {
      const query = searchBoxText;
      if (query.length <= 0) {
        setIsError(true);
      } else {
        const requestUrl = !isRootDomain
          ? `/admin/userProfiles`
          : '/superadmin/userProfiles';
        const result = await reqAdminUserProfiles({
          method: 'GET',
          url: requestUrl,
          params: {
            email: searchBoxText.toLowerCase().trim(),
            ...(organizationSlug && { organizationSlug }),
          },
        });
        if (result.data && result.data.length > 0) {
          navigate(`/users/${result.data[0].id}`);
        } else {
          setIsError(true);
          setSearchBoxText(query);
        }
      }
    } catch (err: unknown) {
      console.error('AdminUser Lookup Failed', { err });
    }
  }, [
    isRootDomain,
    navigate,
    organizationSlug,
    reqAdminUserProfiles,
    searchBoxText,
  ]);

  const handleSearchBoxInputChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setIsError(false);
    const nextSearchBoxText = e.target.value;
    setSearchBoxText(nextSearchBoxText);
  };

  function handleSearchButtonClick() {
    lookupAdminUserProfile();
  }

  const isLoading = isLoadingAdminUserProfiles();

  // Render
  return (
    <PageView
      title="Users"
      subtitles={['Search for a user']}
      headerSx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
      headerElement={
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={1}
          sx={{ width: '100%' }}
        >
          <SearchBar
            placeholder="Search by email address"
            width={isMobile ? 'full-width' : '346px'}
            onChange={handleSearchBoxInputChange}
            error={isError}
            value={searchBoxText}
          />
          <Button
            variant="contained"
            label={'Search'}
            fullWidth={isMobile}
            onClick={handleSearchButtonClick}
          />
        </Stack>
      }
    >
      <Card
        sx={{
          height: '250px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <AlertBox
            title={isError ? 'Not found' : 'Users'}
            variant={'icon'}
            iconVariant={isError ? 'warning' : 'info'}
            description={
              isError
                ? 'No users with that email address could be found'
                : 'Search, edit and manage users in your organisation'
            }
          />
        )}
      </Card>
    </PageView>
  );
}
