import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { testSlice } from 'store/slices';

export type ReduxPageProps = {
  title: string;
};
/**
 *  @deprecated Demo page. Wiill be deleted.
 */
export function ReduxPage({ title }: ReduxPageProps) {
  const dispatch = useAppDispatch();

  const [count, setCount] = useState<number>(0);

  const { setText, setBoolean } = testSlice.actions;
  const { text, boolean } = useAppSelector((state) => state.test);

  function dispatchSetText() {
    setCount(count + 1);
    dispatch(setText(`Test updated ${count} times!`));
  }

  function dispatchSetBoolean() {
    dispatch(setBoolean(!boolean));
  }

  return (
    <>
      <h2>{title}</h2>
      <p>{text}</p>
      <p>{boolean ? 'true' : 'false'}</p>
      <button onClick={() => dispatchSetText()}>Set Text</button>
      <button onClick={() => dispatchSetBoolean()}>Set Boolean</button>
    </>
  );
}
