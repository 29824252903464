import { useAuth } from '@guider-global/auth-hooks';
import {
  useAdminMatches,
  useAdminProgramProfiles,
  useAdminPrograms,
} from 'hooks';
import { useState } from 'react';
import { Stack } from '@guider-global/ui';
import { Box } from '@mui/material';
import { getSubDomain } from '@guider-global/front-end-utils';

export type RestPageProps = {
  title: string;
};

/**
 *  @deprecated Demo page. Wiill be deleted.
 */
export function DemoSugestedMatches({ title }: RestPageProps) {
  // Form state
  const [traineeProfile, setTraineeProfile] = useState<string>();
  const [traineeProfilesInputValue, setTraineeProfilesInputValue] =
    useState<string>('');
  const [organizationSlug, setOrganizationSlug] = useState<string>(
    getSubDomain(),
  );
  const [programSlug, setProgramSlug] = useState<string>();

  // Auth hook
  const { isAuthenticated, isAuthorized, isLoading } = useAuth({});

  const {
    isLoadingAdminPrograms,
    adminPrograms,
    getErrorsAdminPrograms,
    getCode: getAdminProgramsCode,
    isErrorAdminPrograms,
  } = useAdminPrograms({
    getSilently: true,
    forceRefresh: true,
    getSilentlyUrl: `/admin/programs?organizationSlug=${organizationSlug}`,
  });

  const {
    isLoadingAdminProgramProfiles,
    adminProgramProfiles,
    reqAdminProgramProfiles,
    getErrorsAdminProgramProfiles,
    getCode: getAdminProgramProfilesCode,
    isErrorAdminProgramProfiles,
  } = useAdminProgramProfiles({
    getSilently: true,
    forceRefresh: true,
    getSilentlyUrl: `/admin/programProfiles/${programSlug}?organizationSlug=${organizationSlug}`,
  });

  const {
    isLoadingAdminMatches,
    adminMatches,
    reqAdminMatches,
    getErrorsAdminMatches,
    getCodeAdminMatches,
    isErrorAdminMatches,
  } = useAdminMatches({
    forceRefresh: false,
    getSilently: false,
  });

  const programs = adminPrograms().map(
    ({ programSlug, programName, programTypeName, programVariation }) => {
      return { programSlug, programName, programTypeName, programVariation };
    },
  );
  const profiles = adminProgramProfiles().map(
    ({ id, email, userId, activeRelationshipsCount }) => {
      return { id, email, userId, activeRelationshipsCount };
    },
  );
  const matches = adminMatches();

  const isReadyAuth = !isLoading && isAuthenticated && isAuthorized;

  const isReadyApi =
    !isLoadingAdminPrograms() &&
    !isLoadingAdminProgramProfiles() &&
    !isLoadingAdminMatches();

  const isReady = isReadyAuth && isReadyApi;

  if (!isReady) {
    return <>Loading..</>;
  }

  return (
    <>
      <h2>{title}</h2>
      <Stack direction={'row'} spacing={1}>
        <Box>
          Organization Slug
          <input
            type="text"
            value={organizationSlug}
            onChange={(e) => setOrganizationSlug(e.target.value)}
          />
        </Box>
        <Box>
          Program Slug
          <input
            type="text"
            value={programSlug}
            onChange={(e) => setProgramSlug(e.target.value)}
          />
          <button
            onClick={async () => {
              await reqAdminProgramProfiles({
                url: `/admin/programProfiles/${programSlug}?organizationSlug=${organizationSlug}`,
              });
            }}
          >
            Get users
          </button>
        </Box>
        {programSlug && (
          <Box>
            Trainee User(s)
            <input
              type="text"
              onChange={(e) => setTraineeProfilesInputValue(e.target.value)}
            />
            <button
              onClick={() => setTraineeProfile(traineeProfilesInputValue)}
            >
              Add
            </button>
            <p>
              <pre>{JSON.stringify(traineeProfile, null, 2)}</pre>
            </p>
          </Box>
        )}
        {programSlug && (
          <Box>
            <button
              onClick={async () =>
                await reqAdminMatches({
                  method: 'GET',
                  url: `/admin/matches`,
                  params: {
                    organizationSlug,
                    programSlug,
                    userId: traineeProfilesInputValue,
                  },
                })
              }
            >
              GET /admin/matches
            </button>
          </Box>
        )}
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <Box>
          <h3>Programs State:</h3>
          <p>
            <pre>
              {!isLoadingAdminPrograms() && JSON.stringify(programs, null, 2)}
            </pre>
          </p>
          <h3>Programs Errors ({getAdminProgramsCode()}): </h3>
          <p>
            <pre>
              {isErrorAdminPrograms() &&
                JSON.stringify(getErrorsAdminPrograms(), null, 2)}
            </pre>
          </p>
        </Box>
        <Box>
          <h3>Program Profiles State:</h3>
          <p>
            <pre>
              {!isLoadingAdminProgramProfiles() &&
                JSON.stringify(profiles, null, 2)}
            </pre>
          </p>
          <h3>Program Profiles Errors ({getAdminProgramProfilesCode()}):</h3>
          <p>
            <pre>
              {isErrorAdminProgramProfiles() &&
                JSON.stringify(getErrorsAdminProgramProfiles(), null, 2)}
            </pre>
          </p>
        </Box>
        <Box>
          <h3>Manual Match State:</h3>
          <p>
            <pre>
              {!isLoadingAdminMatches() && JSON.stringify(matches, null, 2)}
            </pre>
          </p>
          <h3>Manual Match Errors ({getCodeAdminMatches()}):</h3>
          <p>
            <pre>
              {isErrorAdminMatches() &&
                JSON.stringify(getErrorsAdminMatches(), null, 2)}
            </pre>
          </p>
        </Box>
      </Stack>
    </>
  );
}
