import { useAuth } from '@guider-global/auth-hooks';
import {
  exportCSVFile,
  firstLetterUppercase,
  getCountryCodeName,
} from '@guider-global/front-end-utils';
import {
  getTimezoneAbbreviation,
  getUniqueElements,
} from '@guider-global/isomorphic-utils';
import {
  useSanityOrganization,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  ChartRow,
  IChartsSignupsOverTime,
  SanityProfileField,
} from '@guider-global/shared-types';
import {
  Button,
  Card,
  CustomToolbar,
  ErrorView,
  KeyStatCard,
  MetricsTable,
  Stack,
  Text,
  Chip,
} from '@guider-global/ui';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Refresh from '@mui/icons-material/Refresh';
import {
  Grid as MuiGrid,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  DefaultizedPieValueType,
  PieChart,
  pieArcLabelClasses,
} from '@mui/x-charts';
import { LineChart } from '@mui/x-charts/LineChart';
import {
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
  MuiEvent,
} from '@mui/x-data-grid';
import { useAppContext } from 'context';
import { format } from 'date-fns';
import {
  useAdminChartsGoals,
  useAdminChartsOverviewGuideProfiles,
  useAdminChartsOverviewTraineeProfiles,
  useAdminChartsUsers,
  useAdminKPIsOrganization,
  useAdminPrograms,
} from 'hooks';
import { useAdminChartsSignups } from 'hooks/admin/useAdminChartsSignups';
import { registerLocale as countriesRegisterLocale } from 'i18n-iso-countries';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageView } from 'views';

interface TableRefs {
  [key: string]: React.RefObject<HTMLDivElement>;
}
interface TableState extends GridPaginationModel {
  sortModel: GridSortModel;
}
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 75, 100];

export function MongoMetricsPage() {
  const { organizationSlug, isRootDomain } = useAppContext();
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  const {
    isLoading: isLoadingAuth,
    isAuthenticated,
    isAuthorized,
    accessToken,
    getAccessToken,
  } = useAuth({});

  // Table State
  const [userPaginationModel, setUserPaginationModel] = useState<TableState>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    sortModel: [],
  });
  const [guideMembershipsPaginationModel, setGuideMembershipsPaginationModel] =
    useState<TableState>({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sortModel: [],
    });
  const [
    traineeMembershipsPaginationModel,
    setTraineeMembershipsPaginationModel,
  ] = useState<TableState>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    sortModel: [],
  });
  const [goalsPaginationModel, setGoalsPaginationModel] = useState<TableState>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    sortModel: [],
  });

  const tableRefs: TableRefs = {
    usersTable: useRef<HTMLDivElement>(null),
    guideTable: useRef<HTMLDivElement>(null),
    traineeTable: useRef<HTMLDivElement>(null),
  };

  const { getOrganization } = useSanityOrganization({
    organizationSlug: organizationSlug ?? 'guider',
  });
  const sanityOrganization = getOrganization();

  const { adminPrograms } = useAdminPrograms({
    queryParams: {
      organizationSlug: organizationSlug,
      localeCode: 'en_GB',
    },
    getSilentlyUrl: `/admin/programs`,
  });

  const { getPrograms } = useSanityOrganizationPrograms({
    organizationSlug: organizationSlug,
    localeCode: 'en_GB',
    getSilently: true,
  });
  const programs = getPrograms();

  const traineeOrganizationLabelPluralized = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.trainee
        ?.pluralized,
  );
  const traineeOrganizationLabel = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.trainee
        ?.singular,
  );

  const guideOrganizationLabel = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.guide
        ?.singular,
  );

  const guideOrganizationLabelPluralized = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.guide
        ?.pluralized,
  );

  const traineeLabel = getUniqueElements(traineeOrganizationLabel);
  const traineeLabelPluralized = getUniqueElements(
    traineeOrganizationLabelPluralized,
  );
  const guideLabel = getUniqueElements(guideOrganizationLabel);
  const guideLabelPluralized = getUniqueElements(
    guideOrganizationLabelPluralized,
  );

  const {
    adminChartsGoals: getAdminChartsGoals,
    isLoadingAdminChartsGoals,
    reqAdminChartsGoals,
    getPage: getAdminChartsGoalsPage,
    getMeta: getAdminChartsGoalsMeta,
  } = useAdminChartsGoals({
    forceRefresh: true,
    getSilentlyUrl: isRootDomain ? '/superadmin/charts' : '/admin/charts',
    isPaginated: true,
    pageLimit: goalsPaginationModel.pageSize,
    queryParams: {
      reportName: 'goals',
      ...(isRootDomain && { organizationSlug }),
      page: goalsPaginationModel.page + 1,
      sortModel: goalsPaginationModel.sortModel,
    },
  });
  const goalsMeta = getAdminChartsGoalsMeta();
  const goalsPaginationMeta = goalsMeta?.pagination;
  const goalsTotalRows = goalsPaginationMeta?.isPaginated
    ? goalsPaginationMeta?.totalCount
    : 0;
  const {
    adminChartsUsers: getAdminChartsUsers,
    isLoadingAdminChartsUsers,
    reqAdminChartsUsers,
    getPage: getAdminChartsUsersPage,
    getMeta: getAdminChartsUsersMeta,
  } = useAdminChartsUsers({
    forceRefresh: true,
    getSilentlyUrl: isRootDomain ? '/superadmin/charts' : '/admin/charts',
    pageLimit: userPaginationModel.pageSize,
    isPaginated: true,
    queryParams: {
      reportName: 'users',
      ...(isRootDomain && { organizationSlug }),
      page: userPaginationModel.page + 1,
      sortModel: userPaginationModel.sortModel,
    },
  });
  const usersMeta = getAdminChartsUsersMeta();
  const userPaginationMeta = usersMeta?.pagination;
  const usersTotalRows = userPaginationMeta?.isPaginated
    ? userPaginationMeta?.totalCount
    : 0;
  const {
    adminChartsSignups: getAdminChartsSignups,
    isLoadingAdminChartsSignups,
    reqAdminChartsSignups,
  } = useAdminChartsSignups({
    forceRefresh: true,
    getSilentlyUrl: isRootDomain ? '/superadmin/charts' : '/admin/charts',
    queryParams: {
      reportName: 'signups-over-time',
      ...(isRootDomain && { organizationSlug }),
    },
  });
  const {
    adminChartsOverviewTraineeProfiles: getAdminChartsOverviewTraineeProfiles,
    isLoadingAdminChartsOverviewTraineeProfiles,
    reqAdminChartsOverviewTraineeProfiles,
    getPage: getAdminChartsOverviewTraineeProfilesPage,
    getMeta: getAdminChartsOverviewTraineeProfilesMeta,
  } = useAdminChartsOverviewTraineeProfiles({
    forceRefresh: true,
    getSilentlyUrl: isRootDomain ? '/superadmin/charts' : '/admin/charts',
    isPaginated: true,
    pageLimit: traineeMembershipsPaginationModel.pageSize,
    queryParams: {
      reportName: 'overview-trainee-profiles',
      ...(isRootDomain && { organizationSlug }),
      page: traineeMembershipsPaginationModel.page + 1,
      sortModel: traineeMembershipsPaginationModel.sortModel,
    },
  });
  const overviewTraineeProfileMeta =
    getAdminChartsOverviewTraineeProfilesMeta();
  const overviewTraineeProfilePaginationMeta =
    overviewTraineeProfileMeta?.pagination;
  const overviewTraineeProfileTotalRows =
    overviewTraineeProfilePaginationMeta?.isPaginated
      ? overviewTraineeProfilePaginationMeta?.totalCount
      : 0;
  const {
    adminChartsOverviewGuideProfiles: getAdminChartsOverviewGuideProfiles,
    isLoadingAdminChartsOverviewGuideProfiles,
    reqAdminChartsOverviewGuideProfiles,
    getPage: getAdminAdminChartsOverviewGuideProfilesPage,
    getMeta: getAdminCAdminChartsOverviewGuideProfilesMeta,
  } = useAdminChartsOverviewGuideProfiles({
    forceRefresh: true,
    getSilentlyUrl: isRootDomain ? '/superadmin/charts' : '/admin/charts',
    pageLimit: guideMembershipsPaginationModel.pageSize,
    isPaginated: true,
    queryParams: {
      reportName: 'overview-guide-profiles',
      ...(isRootDomain && { organizationSlug }),
      page: guideMembershipsPaginationModel.page + 1,
      sortModel: guideMembershipsPaginationModel.sortModel,
    },
  });
  const overviewGuideProfileMeta =
    getAdminCAdminChartsOverviewGuideProfilesMeta();
  const overviewGuideProfilePaginationMeta =
    overviewGuideProfileMeta?.pagination;
  const overviewGuideProfileTotalRows =
    overviewGuideProfilePaginationMeta?.isPaginated
      ? overviewGuideProfilePaginationMeta?.totalCount
      : 0;

  const {
    adminKPIsOrganization: getAdminKPIsOrganization,
    isLoadingAdminKPIsOrganization,
    reqAdminKPIsOrganization,
  } = useAdminKPIsOrganization({
    forceRefresh: true,
    getSilentlyUrl: isRootDomain ? '/superadmin/kpis' : '/admin/kpis',
    queryParams: {
      kpiName: 'organization',
      ...(isRootDomain && { organizationSlug }),
    },
  });

  const isLoading =
    isLoadingAuth ||
    !isAuthenticated ||
    !isAuthorized ||
    isLoadingAdminChartsGoals() ||
    isLoadingAdminChartsUsers() ||
    isLoadingAdminChartsOverviewTraineeProfiles() ||
    isLoadingAdminKPIsOrganization() ||
    isLoadingAdminChartsSignups() ||
    isLoadingAdminChartsOverviewGuideProfiles();

  function getUpdatedAt(): string | undefined {
    try {
      const now = new Date();
      const localeData = Intl.DateTimeFormat().resolvedOptions();

      const updatedAtString = `Updated today at ${now.toLocaleTimeString(
        localeData.locale,
        {
          hour12: true,
        },
      )} (${getTimezoneAbbreviation(localeData.timeZone)})`;

      return updatedAtString;
    } catch (err: unknown) {
      return undefined;
    }
  }

  function refresh() {
    reqAdminChartsGoals({
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        reportName: 'goals',
        pageLimit: goalsPaginationModel.pageSize,
        page: goalsPaginationModel.page + 1,
      },
    });
    reqAdminChartsUsers({
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        reportName: 'users',
        pageLimit: userPaginationModel.pageSize,
        page: userPaginationModel.page + 1,
        sortModel: userPaginationModel.sortModel,
      },
    });
    reqAdminKPIsOrganization({
      url: isRootDomain ? '/superadmin/kpis' : '/admin/kpis',
      params: {
        kpiName: 'organization',
      },
    });
    reqAdminChartsOverviewGuideProfiles({
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        reportName: `overview-guide-profiles`,
        pageLimit: guideMembershipsPaginationModel.pageSize,
        page: guideMembershipsPaginationModel.page + 1,
        sortModel: guideMembershipsPaginationModel.sortModel,
      },
    });
    reqAdminChartsOverviewTraineeProfiles({
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        reportName: `overview-trainee-profiles`,
        pageLimit: traineeMembershipsPaginationModel.pageSize,
        page: traineeMembershipsPaginationModel.page + 1,
        sortModel: traineeMembershipsPaginationModel.sortModel,
      },
    });
    reqAdminChartsSignups({
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        reportName: 'signups-over-time',
      },
    });
    getUpdatedAt();
  }

  const [adminKPIsOrganization] = getAdminKPIsOrganization();

  const adminChartsUsers = getAdminChartsUsers()[0];

  const adminChartsSignups = getAdminChartsSignups();

  const adminChartsOverviewGuideProfile =
    getAdminChartsOverviewGuideProfiles()[0];

  const adminChartsOverviewTraineeProfile =
    getAdminChartsOverviewTraineeProfiles()[0];

  const navigate = useNavigate();

  const today = new Date().toLocaleDateString();

  const userRows = adminChartsUsers?.rows ?? [];

  const overviewTraineeProfilesRows =
    adminChartsOverviewTraineeProfile?.rows ?? [];

  const overviewGuideProfilesRows = adminChartsOverviewGuideProfile?.rows ?? [];

  const sanityOrganizationFields =
    sanityOrganization.profile_fields as SanityProfileField[];
  const sanityOrganizationPersonalDetails = sanityOrganization.personal_details;

  const sanityOrganizationFieldColumns: GridColDef[] =
    sanityOrganizationFields?.map((field) => {
      return {
        field: field.id.current,
        headerName: field.text.label,
      };
    }) ?? [];

  countriesRegisterLocale(require(`i18n-iso-countries/langs/en.json`));
  const userColumnsParsed: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      minWidth: 100,
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
    },
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 0.5 },
    {
      field: 'givenName',
      headerName: 'First name',
      minWidth: 100,
    },
    { field: 'familyName', headerName: 'Last name', minWidth: 100 },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 150,
      valueGetter: (params) => params.row.role,
      renderCell: (params) => {
        const getChipColor = () => {
          if (params.row.role === guideLabel) return '#00307A';
          if (params.row.role === traineeLabel) return '#008CF9';
          if (params.row.role === 'Both') return '#00BB92';
          return undefined;
        };
        const chipColor = getChipColor();
        return (
          <Chip
            label={params.row.role}
            size="small"
            bgColor={chipColor}
            sx={{
              display: params.row.role === 'n/a' ? 'none' : '',
            }}
          />
        );
      },
    },
    {
      field: 'programsWhereIsGuide',
      headerName: `${guideLabel} profiles`,
      minWidth: 200,
    },
    {
      field: 'programsWhereIsTrainee',
      headerName: `${traineeLabel} profiles`,
      minWidth: 200,
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
    },
    {
      field: 'concludedRelationships',
      headerName: 'Concluded relationships',
    },

    { field: 'status', headerName: 'Status' },
    { field: 'sessionsHeld', headerName: 'Sessions held' },
    { field: 'jobRole', headerName: 'Job role' },
    { field: 'townOrCity', headerName: 'Town/City' },
    {
      field: 'country',
      headerName:
        sanityOrganizationPersonalDetails.country.custom_label ?? 'Country',
      valueGetter: ({ value }) => getCountryCodeName(value),
    },
    { field: 'goals', headerName: 'Goals' },
    ...sanityOrganizationFieldColumns,
  ];

  function getProgramsLabelsBySlugs(
    programSlug: string | number | string[] | Date | undefined,
  ): string {
    const programsSlugs = Array.isArray(programSlug)
      ? programSlug.map(String)
      : (String(programSlug).split(';') || []).map((slug) => slug.trim());

    const programsLabels = programsSlugs
      .map(
        (slug) =>
          adminPrograms().find((program) => program.programSlug === slug)
            ?.programName,
      )
      .filter((label) => label !== undefined);

    return programsLabels.length > 0 ? programsLabels.join(', ') : 'n/a';
  }

  const parsedUserRows = userRows.map((row) => {
    const {
      id,
      createdAt,
      givenName,
      familyName,
      email,
      programsWhereIsGuide,
      programsWhereIsTrainee,
      activeRelationships,
      concludedRelationships,
      role,
      status,
      sessionsHeld,
      jobRole,
      townOrCity,
      country,
      goals,
      bio,
      ...rest
    } = row;

    const roles = {
      guide: `${guideLabel}`,
      trainee: `${traineeLabel}`,
      both: 'Both',
      noMembership: 'n/a',
    };

    const statuses = {
      matched: 'Matched',
      joinedProgram: 'Program profile completed',
      completedProfile: 'Account created',
      signedUp: 'Signed up',
    };

    const parsedRole = roles[role ?? 'noMembership'];
    const parsedStatus = statuses[status ?? 'signedUp'];

    const parsedRest = Object.entries(rest).reduce<
      Record<string, string | undefined>
    >((acc, [key, value]) => {
      const fieldSlug = key;
      const sanityOrganizationField = sanityOrganizationFields?.find(
        (field) => field.id.current === fieldSlug,
      ) as SanityProfileField;

      const type = sanityOrganizationField?.type;

      if (type === 'select') {
        const valueLabel = sanityOrganizationField?.options?.find(
          (option) => option.id.current === value,
        )?.label;
        return { ...acc, [key]: valueLabel };
      }

      if (type === 'multi-select') {
        const valueLabels = Array.isArray(value)
          ? value?.map(
              (value) =>
                sanityOrganizationField?.options?.find(
                  (option) => option.id.current === value,
                )?.label,
            )
          : [];

        return { ...acc, [key]: valueLabels.join(', ') };
      }

      if (type === 'free-text') {
        return { ...acc, [key]: `${value}` };
      }
      return acc;
    }, {});

    return {
      id,
      createdAt,
      givenName,
      familyName,
      email,
      programsWhereIsGuide: getProgramsLabelsBySlugs(programsWhereIsGuide),
      programsWhereIsTrainee: getProgramsLabelsBySlugs(programsWhereIsTrainee),
      activeRelationships,
      concludedRelationships,
      role: parsedRole,
      status: parsedStatus,
      sessionsHeld,
      jobRole,
      townOrCity,
      country,
      goals,
      bio,
      ...parsedRest,
    };
  });

  function handleEvent(
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    details: GridCallbackDetails,
  ): void {
    navigate(`/users/${params.id}`);
  }

  const parsedGuideMembershipsRows = overviewGuideProfilesRows.map((row) => {
    const {
      id,
      firstName,
      lastName,
      email,
      programsWhereIsGuide,
      activeRelationships,
      concludedRelationships,
      sessionsHeld,
    } = row;

    return {
      id,
      firstName,
      lastName,
      email,
      programsWhereIsGuide: getProgramsLabelsBySlugs(programsWhereIsGuide),
      activeRelationships,
      concludedRelationships,
      sessionsHeld,
    };
  });

  const parsedTraineeMembershipsRows = overviewTraineeProfilesRows.map(
    (row) => {
      const {
        id,
        firstName,
        lastName,
        email,
        programsWhereIsTrainee,
        activeRelationships,
        concludedRelationships,
        sessionsHeld,
      } = row;

      return {
        id,
        firstName,
        lastName,
        email,
        programsWhereIsTrainee: getProgramsLabelsBySlugs(
          programsWhereIsTrainee,
        ),
        activeRelationships,
        concludedRelationships,
        sessionsHeld,
      };
    },
  );

  const guideMembershipsColumns = [
    { field: 'firstName', headerName: 'First name', minWidth: 100 },
    { field: 'lastName', headerName: 'Last name', minWidth: 100 },
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 0.3 },
    {
      field: 'programsWhereIsGuide',
      headerName: `${guideLabel} profiles`,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
    },
    {
      field: 'concludedRelationships',
      headerName: 'Concluded relationships',
    },
    { field: 'sessionsHeld', headerName: 'Sessions held' },
  ];

  const traineeMembershipsColumns = [
    { field: 'firstName', headerName: 'First name', minWidth: 100 },
    { field: 'lastName', headerName: 'Last name', minWidth: 100 },
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 0.3 },
    {
      field: 'programsWhereIsTrainee',
      headerName: `${traineeLabel} Profiles`,
      minWidth: 200,
      flex: 0.5,
    },
    { field: 'activeRelationships', headerName: 'Active relationships' },
    { field: 'concludedRelationships', headerName: 'Concluded relationships' },
    { field: 'sessionsHeld', headerName: 'Sessions held' },
  ];

  const adminChartsGoals = getAdminChartsGoals()[0];

  const goalRows = adminChartsGoals?.rows || [];

  const parsedGoalRows = goalRows.map((row) => {
    const { createdAt, updatedAt, category, status, ...rest } = row;

    const sanityGoalCategories =
      sanityOrganization?.goal_categories.goal_categories;

    const parsedStatus = () => {
      switch (status) {
        case 'on_track':
          return 'On Track';
        case 'off_track':
          return 'Off Track';
        case 'on_hold':
          return 'On Hold';
        case 'completed':
          return 'Completed';

        default:
          return status;
      }
    };

    return {
      createdAt,
      updatedAt,
      category: sanityGoalCategories.find(
        (goal) => goal.goal_category_name === category,
      )?.goal_category_name,
      status: parsedStatus(),
      ...rest,
    };
  });

  const goalColumns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    { field: 'userFirstName', headerName: 'First name', minWidth: 100 },
    { field: 'userLastName', headerName: 'Last name', minWidth: 100 },
    {
      field: 'category',
      headerName: 'Goal category',
      flex: 1,
    },
    {
      field: 'objective',
      headerName: 'Goal objective',
      flex: 0.8,
    },
    { field: 'shared', headerName: 'Shared goal' },
    {
      field: 'status',
      minWidth: 130,
      headerName: 'Goal status',
      valueGetter: (params) => params.row.status,
      renderCell: (params) => {
        const getChipColor = () => {
          if (params.row.status === 'Completed') return 'info';
          if (params.row.status === 'On Hold') return 'warning';
          if (params.row.status === 'Off Track') return 'error';
          return 'success';
        };
        const chipColor = getChipColor();
        return (
          <Chip
            label={firstLetterUppercase(params.row.status)}
            size="small"
            color={chipColor}
          />
        );
      },
    },
  ];

  const keyStats = [
    {
      label: 'Users',
      toolTipMessage: 'Number of users registered to your organization',
      value: adminKPIsOrganization?.memberCount ?? 0,
      key: 'users',
      description: 'users',
      ref: 'usersTable',
    },
    {
      label: 'Relationships',
      toolTipMessage: 'Number of matches in your organization',
      value: adminKPIsOrganization?.relationshipCount ?? 0,
      key: 'relationships',
      description: 'relationships',
    },

    {
      label: 'Concluded relationships',
      value: adminKPIsOrganization?.concludedRelationshipCount ?? 0,
      key: 'concludedRelationships',
      toolTipMessage: 'Number of concluded relationships in your organization',
      percentageNumber:
        adminKPIsOrganization?.concludedRelationshipCount &&
        adminKPIsOrganization?.relationshipCount
          ? adminKPIsOrganization?.concludedRelationshipCount /
            adminKPIsOrganization?.relationshipCount
          : 0,
      description:
        adminKPIsOrganization?.concludedRelationshipCount &&
        adminKPIsOrganization?.relationshipCount
          ? `${Math.ceil(
              (adminKPIsOrganization?.concludedRelationshipCount /
                adminKPIsOrganization?.relationshipCount) *
                100,
            )}% of relationships`
          : '',
    },
    {
      label: `${guideLabel} memberships`,
      toolTipMessage: `Number of users that are ${guideLabelPluralized} in your organization`,
      value: adminKPIsOrganization?.guideMemberships ?? 0,
      key: 'guideMemberships',
      percentageNumber:
        adminKPIsOrganization?.guideMemberships /
        adminKPIsOrganization?.memberCount,
      description: `${Math.ceil(
        (adminKPIsOrganization?.guideMemberships /
          adminKPIsOrganization?.memberCount) *
          100,
      )}% of users are ${guideLabelPluralized}`,
      ref: 'guideTable',
    },
    {
      label: `${traineeLabel} memberships`,
      toolTipMessage: `Number of users that are ${traineeLabelPluralized} in your organization`,
      value: adminKPIsOrganization?.traineeMemberships ?? 0,
      key: 'traineeMemberships',
      percentageNumber:
        adminKPIsOrganization?.traineeMemberships /
        adminKPIsOrganization?.memberCount,
      description: `${Math.ceil(
        (adminKPIsOrganization?.traineeMemberships /
          adminKPIsOrganization?.memberCount) *
          100,
      )}% of users are ${traineeLabelPluralized}`,
      ref: 'traineeTable',
    },

    {
      label: 'Sessions',
      toolTipMessage: 'Number of sesions booked in your organization',
      value: adminKPIsOrganization?.sessionCount ?? 0,
      key: 'sessions',
      description: 'sessions',
    },
  ];

  const relationshipsPieChartData = [
    {
      label: 'Active',
      value: adminKPIsOrganization?.activeRelationshipCount,
      color: palette.success.main,
    },
    {
      label: 'Concluded',
      value: adminKPIsOrganization?.concludedRelationshipCount,
      color: '#C6D9D5',
    },
  ];

  const membershipsPieChartData = [
    {
      label: `${guideLabelPluralized}`,
      value: adminKPIsOrganization?.guideMemberships,
      color: palette.warning.main,
    },
    {
      label: `${traineeLabelPluralized}`,
      value: adminKPIsOrganization?.traineeMemberships,
      color: palette.warning.light,
    },
  ];

  const relationshipsTotal = relationshipsPieChartData
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);

  const getRelatinoshipsArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / relationshipsTotal;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const membershipsTotal = membershipsPieChartData
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);

  const getMembershipsArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / membershipsTotal;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const graphDataset: ChartRow<IChartsSignupsOverTime>[] = adminChartsSignups
    .flatMap((signup) => {
      return signup.rows;
    })
    .sort((a, b) => {
      if (a?.createdAt && b?.createdAt) {
        if (a?.createdAt > b?.createdAt) {
          return 1;
        }
        if (a?.createdAt < b?.createdAt) {
          return -1;
        }
      }

      return 0;
    });

  const xAxisData = graphDataset.map((data) => new Date(data.createdAt!));

  const toolbar = useCallback(
    ({
      fileName,
      headers,
      reportName,
    }: {
      fileName: string;
      headers: string[];
      reportName: string;
    }) =>
      CustomToolbar({
        fileName,
        headers,
        url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
        params: {
          reportName,
        },
        accessToken,
        onExpiredAccessToken: getAccessToken,
      }),
    [accessToken, getAccessToken, isRootDomain],
  );

  if (isMobile) {
    return (
      <ErrorView
        title="Screen size not supported yet"
        message="We are sorry but this screen size is not supported. Please switch to your desktop or laptop to view the metrics."
        iconVariant="warning"
        buttons={[]}
      />
    );
  }

  const handleCardClick = (cardName: string) => {
    if (tableRefs[cardName].current) {
      tableRefs[cardName]!.current!.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <PageView
      title={'Overview'}
      subtitles={[
        'Think of this overview as the movie trailer for your data saga - all the highlights without the spoilers.',
      ]}
      headerElement={
        <Stack direction={'column'} alignItems={'flex-end'} spacing={0}>
          <Button
            variant="textWithIcon"
            startIcon={<Refresh />}
            label="Refresh data"
            onClick={refresh}
            loading={isLoading}
          />
          <Text
            text={getUpdatedAt()}
            variant="subtitle2"
            color={palette.paper.text.disabled}
          />
        </Stack>
      }
      sx={{ backgroundColor: palette.paper.background.primary }}
    >
      <Stack direction="column" width="100%" gap={5}>
        <MuiGrid container direction="row" alignItems="stretch" gap={1}>
          {keyStats.map((stat, index) => (
            <MuiGrid key={stat.key} item md={5.9} lg={3.9}>
              {isLoadingAdminKPIsOrganization() ? (
                <Skeleton
                  variant="rounded"
                  height="114px"
                  sx={{ borderRadius: '8px' }}
                />
              ) : (
                <KeyStatCard
                  key={`${stat.key}`}
                  label={stat.label}
                  toolTipMessage={stat.toolTipMessage}
                  value={stat.value}
                  description={stat.description}
                  percentageNumber={stat.percentageNumber}
                  cardRef={
                    stat.ref ? () => handleCardClick(stat?.ref) : undefined
                  }
                />
              )}
            </MuiGrid>
          ))}
        </MuiGrid>
        <MuiGrid container rowSpacing={3} direction="row">
          {isLoading ? (
            <Skeleton
              variant="rounded"
              height="258px"
              sx={{ borderRadius: '8px', flex: 1, mr: '12px' }}
            />
          ) : (
            <Card
              title="Sign ups over time"
              sx={{
                height: '400px',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <LineChart
                xAxis={[
                  {
                    id: 'signupDate',
                    data: xAxisData,
                    scaleType: 'time',
                    valueFormatter: (xAxisData) =>
                      xAxisData.toLocaleDateString(),
                  },
                ]}
                series={[
                  {
                    data: graphDataset.map((data) => data.profilesCreated!),
                    color: palette.info.main,
                    curve: 'linear',
                    showMark: false,
                  },
                ]}
              />
            </Card>
          )}
        </MuiGrid>
        <MuiGrid container rowSpacing={2} direction="row">
          {isLoading ? (
            <Skeleton
              variant="rounded"
              height="258px"
              sx={{ borderRadius: '8px', flex: 1, mr: '12px' }}
            />
          ) : (
            <Card
              title="Relationships"
              sx={{
                height: '300px',
                mr: 3,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
                pr: 1,
              }}
            >
              <Button
                label={'Export'}
                color={'primary'}
                startIcon={<FileDownloadOutlinedIcon />}
                variant="textWithIcon"
                onClick={() =>
                  exportCSVFile({
                    rows: relationshipsPieChartData,
                    fileName: `${organizationSlug}-relationships-${today}`,
                  })
                }
                sx={{
                  alignSelf: 'flex-end',
                  position: 'absolute',
                  px: '4px',
                  m: 0.5,
                  height: '32px',
                  '&:hover': {
                    borderColor: 'cubic-bezier(0.4, 0, 0.2, 1);',
                  },
                }}
              />
              <PieChart
                series={[
                  {
                    data: relationshipsPieChartData,
                    arcLabel: getRelatinoshipsArcLabel,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    cursor: 'default',
                    pointerEvents: 'none',
                  },
                }}
              />
            </Card>
          )}
          {isLoading ? (
            <Skeleton
              variant="rounded"
              height="258px"
              sx={{ borderRadius: '8px', flex: 1, ml: '12px' }}
            />
          ) : (
            <Card
              title="Memberships"
              sx={{
                height: '300px',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
                pr: 1,
              }}
            >
              <Button
                label={'Export'}
                color={'primary'}
                startIcon={<FileDownloadOutlinedIcon />}
                variant="textWithIcon"
                onClick={() =>
                  exportCSVFile({
                    headers: ['label', 'value'],
                    rows: membershipsPieChartData,
                    fileName: `${organizationSlug}-memberships-${today}`,
                  })
                }
                sx={{
                  alignSelf: 'flex-end',
                  position: 'absolute',
                  px: '4px',
                  m: 0.5,
                  height: '32px',
                  '&:hover': {
                    borderColor: 'cubic-bezier(0.4, 0, 0.2, 1);',
                  },
                }}
              />
              <PieChart
                series={[
                  {
                    data: membershipsPieChartData,
                    arcLabel: getMembershipsArcLabel,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    cursor: 'default',
                    pointerEvents: 'none',
                  },
                }}
              />
            </Card>
          )}
        </MuiGrid>
        <div ref={tableRefs['usersTable']}>
          <MetricsTable
            heading={'All users'}
            rows={parsedUserRows}
            columns={userColumnsParsed}
            loading={isLoadingAdminChartsUsers()}
            onRowClick={handleEvent}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setUserPaginationModel((c) => ({
                ...c,
                sortModel,
              }));
              reqAdminChartsUsers({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  reportName: 'users',
                  sortModel,
                  page: userPaginationModel.page + 1,
                  pageLimit: userPaginationModel.pageSize,
                },
              });
            }}
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            paginationModel={userPaginationModel}
            paginationMode="server"
            onPaginationModelChange={(model) => {
              setUserPaginationModel((c) => ({ ...c, ...model }));
              getAdminChartsUsersPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  pageLimit: model.pageSize,
                  reportName: 'users',
                  sortModel: userPaginationModel.sortModel,
                },
              });
            }}
            rowCount={usersTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-all-users-${today}`,
                headers: userColumnsParsed.map((column) => column.field),
                reportName: 'users',
              })
            }
          />
        </div>
        <div ref={tableRefs['guideTable']}>
          <MetricsTable
            heading={`${guideLabel} memberships`}
            rows={parsedGuideMembershipsRows}
            columns={guideMembershipsColumns}
            loading={isLoadingAdminChartsOverviewGuideProfiles()}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setGuideMembershipsPaginationModel((c) => ({ ...c, sortModel }));
              reqAdminChartsOverviewGuideProfiles({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  reportName: `overview-guide-profiles`,
                  sortModel,
                  page: guideMembershipsPaginationModel.page + 1,
                  pageLimit: guideMembershipsPaginationModel.pageSize,
                },
              });
            }}
            paginationModel={guideMembershipsPaginationModel}
            paginationMode="server"
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={(model) => {
              setGuideMembershipsPaginationModel((c) => ({ ...c, ...model }));
              getAdminAdminChartsOverviewGuideProfilesPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  pageLimit: model.pageSize,
                  reportName: `overview-guide-profiles`,
                  sortModel: guideMembershipsPaginationModel.sortModel,
                },
              });
            }}
            rowCount={overviewGuideProfileTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-guide-membership-${today}`,
                headers: guideMembershipsColumns.map((column) => column.field),
                reportName: 'overview-guide-profiles',
              })
            }
          />
        </div>
        <div ref={tableRefs['traineeTable']}>
          <MetricsTable
            heading={`${traineeLabel} memberships`}
            rows={parsedTraineeMembershipsRows}
            columns={traineeMembershipsColumns}
            loading={isLoadingAdminChartsOverviewTraineeProfiles()}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setTraineeMembershipsPaginationModel((c) => ({
                ...c,
                sortModel,
              }));
              reqAdminChartsOverviewTraineeProfiles({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  reportName: `overview-trainee-profiles`,
                  sortModel,
                  page: traineeMembershipsPaginationModel.page + 1,
                  pageLimit: traineeMembershipsPaginationModel.pageSize,
                },
              });
            }}
            paginationModel={traineeMembershipsPaginationModel}
            paginationMode="server"
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={(model) => {
              setTraineeMembershipsPaginationModel((c) => ({ ...c, ...model }));
              getAdminChartsOverviewTraineeProfilesPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  pageLimit: model.pageSize,
                  reportName: `overview-trainee-profiles`,
                  sortModel: traineeMembershipsPaginationModel.sortModel,
                },
              });
            }}
            rowCount={overviewTraineeProfileTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-trainee-membership-${today}`,
                headers: traineeMembershipsColumns.map(
                  (column) => column.field,
                ),
                reportName: 'overview-trainee-profiles',
              })
            }
          />
        </div>
        <MetricsTable
          heading={'Goals'}
          rows={parsedGoalRows}
          columns={goalColumns}
          loading={isLoadingAdminChartsGoals()}
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            setGoalsPaginationModel((c) => ({
              ...c,
              sortModel,
            }));
            reqAdminChartsGoals({
              url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
              params: {
                reportName: 'goals',
                sortModel,
                page: goalsPaginationModel.page + 1,
                pageLimit: goalsPaginationModel.pageSize,
              },
            });
          }}
          paginationModel={goalsPaginationModel}
          paginationMode="server"
          pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
          onPaginationModelChange={(model) => {
            setGoalsPaginationModel((c) => ({
              ...c,
              ...model,
            }));
            getAdminChartsGoalsPage({
              url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
              page: model.page + 1,
              params: {
                pageLimit: model.pageSize,
                reportName: 'goals',
                sortModel: goalsPaginationModel.sortModel,
              },
            });
          }}
          rowCount={goalsTotalRows}
          toolbar={() =>
            toolbar({
              fileName: `${organizationSlug}-goals-${today}`,
              headers: goalColumns.map((column) => column.field),
              reportName: 'goals',
            })
          }
        />
      </Stack>
    </PageView>
  );
}
