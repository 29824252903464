import { useAuth } from '@guider-global/auth-hooks';
import {
  exportCSVFile,
  firstLetterUppercase,
} from '@guider-global/front-end-utils';
import { getTimezoneAbbreviation } from '@guider-global/isomorphic-utils';
import {
  useSanityOrganization,
  useSanityProgram,
} from '@guider-global/sanity-hooks';

import {
  Button,
  Card,
  CustomToolbar,
  ErrorView,
  KeyStatCard,
  MetricsTable,
  Stack,
  Text,
} from '@guider-global/ui';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Refresh from '@mui/icons-material/Refresh';
import {
  Chip,
  Grid as MuiGrid,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  DefaultizedPieValueType,
  PieChart,
  pieArcLabelClasses,
} from '@mui/x-charts';
import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { useAppContext } from 'context';
import { format } from 'date-fns';
import {
  useAdminChartsProgramActiveRelationships,
  useAdminChartsProgramConcludedRelationships,
  useAdminChartsProgramGoals,
  useAdminChartsProgramGuideProfiles,
  useAdminChartsProgramTraineeProfiles,
  useAdminChartsSessions,
  useAdminChartsSkillsOffered,
  useAdminChartsSkillsSought,
  useAdminKPIsProgram,
} from 'hooks';
import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageView } from 'views';

interface TableRefs {
  [key: string]: React.RefObject<HTMLDivElement>;
}
interface TableState extends GridPaginationModel {
  sortModel: GridSortModel;
}

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 75, 100];

export function ProgramMetricsPage() {
  const { organizationSlug, isRootDomain } = useAppContext();
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const { programSlug } = useParams();

  const { getProgram, isLoadingSanityProgram } = useSanityProgram({
    getSilently: true,
    programSlug,
    localeCode: 'en_GB',
    forceRefresh: true,
  });

  const { getOrganization } = useSanityOrganization({
    organizationSlug: organizationSlug ?? 'guider',
  });
  const sanityOrganization = getOrganization();
  const program = getProgram();
  const programTypeName =
    program?.program_details?.program_type?.program_type_text.common?.verb;
  const programName = program?.metadata.program_name;
  const programType = program?.program_details?.program_type;
  const guideOrganizationLabel =
    programType?.program_type_text.common?.guide?.singular;
  const traineeOrganizationLabel =
    programType?.program_type_text.common?.trainee?.singular;
  const guideOrganizationLabelPluralized =
    programType?.program_type_text.common?.guide?.pluralized;
  const traineeOrganizationLabelPluralized =
    programType?.program_type_text.common?.trainee?.pluralized;

  const tableRefs: TableRefs = {
    guideTable: useRef<HTMLDivElement>(null),
    traineeTable: useRef<HTMLDivElement>(null),
    sessions: useRef<HTMLDivElement>(null),
    activeRelationships: useRef<HTMLDivElement>(null),
    concludedRelationships: useRef<HTMLDivElement>(null),
  };

  const {
    isLoading: isLoadingAuth,
    isAuthenticated,
    isAuthorized,
    accessToken,
    getAccessToken,
  } = useAuth({});

  const {
    adminKPIsProgram: getAdminKPIsProgram,
    reqAdminKPIsProgram,
    isLoadingAdminKPIsProgram,
  } = useAdminKPIsProgram({
    getSilentlyUrl: isRootDomain ? `/superadmin/kpis` : `/admin/kpis`,
    queryParams: {
      ...(isRootDomain && { organizationSlug }),
      programSlug,
      kpiName: 'program',
    },
    forceRefresh: true,
  });

  const [programGuidePaginationModel, setProgramGuidePaginationModel] =
    useState<TableState>({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sortModel: [],
    });
  const {
    adminChartsProgramGuideProfiles: getAdminChartsProgramGuideProfiles,
    isLoadingAdminChartsProgramGuideProfiles,
    reqAdminChartsProgramGuideProfiles,
    getPage: getAdminChartsProgramGuideProfilesPage,
    getMeta: getAdminChartsProgramGuideProfilesMeta,
  } = useAdminChartsProgramGuideProfiles({
    getSilentlyUrl: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
    pageLimit: programGuidePaginationModel.pageSize,
    isPaginated: true,
    queryParams: {
      ...(isRootDomain && { organizationSlug }),
      programSlug,
      reportName: 'program-guide-profiles',
      page: programGuidePaginationModel.page + 1,
      sortModel: programGuidePaginationModel.sortModel,
    },
    forceRefresh: true,
  });
  const programGuideMeta = getAdminChartsProgramGuideProfilesMeta();
  const programGuidePaginationMeta = programGuideMeta?.pagination;
  const programGuideTotalRows = programGuidePaginationMeta?.isPaginated
    ? programGuidePaginationMeta?.totalCount
    : 0;

  const [programTraineePaginationModel, setProgramTraineePaginationModel] =
    useState<TableState>({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sortModel: [],
    });
  const {
    adminChartsProgramTraineeProfiles: getAdminChartsProgramTraineeProfiles,
    reqAdminChartsProgramTraineeProfiles,
    isLoadingAdminChartsProgramTraineeProfiles,
    getPage: getAdminChartsProgramTraineeProfilesPage,
    getMeta: getAdminChartsProgramTraineeProfilesMeta,
  } = useAdminChartsProgramTraineeProfiles({
    getSilentlyUrl: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
    pageLimit: programTraineePaginationModel.pageSize,
    isPaginated: true,
    queryParams: {
      ...(isRootDomain && { organizationSlug }),
      programSlug,
      reportName: 'program-trainee-profiles',
      page: programGuidePaginationModel.page + 1,
      sortModel: programGuidePaginationModel.sortModel,
    },
    forceRefresh: true,
  });
  const programTraineeMeta = getAdminChartsProgramTraineeProfilesMeta();
  const programTraineePaginationMeta = programTraineeMeta?.pagination;
  const programTraineeTotalRows = programTraineePaginationMeta?.isPaginated
    ? programTraineePaginationMeta?.totalCount
    : 0;

  const [
    programActiveRelationshipsPaginationModel,
    setProgramActiveRelationshipsPaginationModel,
  ] = useState<TableState>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    sortModel: [],
  });
  const {
    adminChartsProgramActiveRelationships:
      getAdminChartsProgramActiveRelationships,
    isLoadingAdminChartsProgramActiveRelationships,
    reqAdminChartsProgramActiveRelationships,
    getPage: getAdminChartsProgramActiveRelationshipsProfilesPage,
    getMeta: getAdminChartsProgramActiveRelationshipsProfilesMeta,
  } = useAdminChartsProgramActiveRelationships({
    getSilentlyUrl: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
    pageLimit: programActiveRelationshipsPaginationModel.pageSize,
    isPaginated: true,
    queryParams: {
      ...(isRootDomain && { organizationSlug }),
      programSlug,
      reportName: 'program-active-relationships',
      page: programActiveRelationshipsPaginationModel.page + 1,
      sortModel: programActiveRelationshipsPaginationModel.sortModel,
    },
    forceRefresh: true,
  });
  const programActiveRelationshipsMeta =
    getAdminChartsProgramActiveRelationshipsProfilesMeta();
  const programActiveRelationshipsPaginationMeta =
    programActiveRelationshipsMeta?.pagination;
  const programActiveRelationshipsTotalRows =
    programActiveRelationshipsPaginationMeta?.isPaginated
      ? programActiveRelationshipsPaginationMeta.totalCount
      : 0;

  const [
    programConcludedRelationshipsPaginationModel,
    setProgramConcludedRelationshipsPaginationModel,
  ] = useState<TableState>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    sortModel: [],
  });
  const {
    adminChartsProgramConcludedRelationships:
      getAdminChartsProgramConcludedRelationships,
    isLoadingAdminChartsProgramConcludedRelationships,
    reqAdminChartsProgramConcludedRelationships,
    getPage: getConcludedRelationshipsPage,
    getMeta: getConcludedRelationshipsMeta,
  } = useAdminChartsProgramConcludedRelationships({
    getSilentlyUrl: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
    pageLimit: programConcludedRelationshipsPaginationModel.pageSize,
    isPaginated: true,
    queryParams: {
      page: programConcludedRelationshipsPaginationModel.page + 1,
      ...(isRootDomain && { organizationSlug }),
      programSlug,
      reportName: 'program-concluded-relationships',
      sortModel: programConcludedRelationshipsPaginationModel.sortModel,
    },
    forceRefresh: true,
  });
  const concludedRelationshipsMeta = getConcludedRelationshipsMeta();
  const concludedRelationshipsPaginationMeta =
    concludedRelationshipsMeta?.pagination;
  const concludedRelationshipsTotalRows =
    concludedRelationshipsPaginationMeta?.isPaginated
      ? concludedRelationshipsPaginationMeta?.totalCount
      : 0;

  const [programGoalsPaginationModel, setProgramGoalsPaginationModel] =
    useState<TableState>({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sortModel: [],
    });
  const {
    adminChartsProgramGoals: getAdminChartsProgramGoals,
    isLoadingAdminChartsProgramGoals,
    reqAdminChartsProgramGoals,
    getPage: getProgramGoalsPage,
    getMeta: getProgramGoalsMeta,
  } = useAdminChartsProgramGoals({
    getSilentlyUrl: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
    pageLimit: programGoalsPaginationModel.pageSize,
    isPaginated: true,
    queryParams: {
      ...(isRootDomain && { organizationSlug }),
      page: programGoalsPaginationModel.page + 1,
      programSlug,
      reportName: 'program-goals',
      sortModel: programGoalsPaginationModel.sortModel,
    },
    forceRefresh: true,
  });
  const programGoalsMeta = getProgramGoalsMeta();
  const programGoalsPaginationMeta = programGoalsMeta?.pagination;
  const programGoalsTotalRows = programGoalsPaginationMeta?.isPaginated
    ? programGoalsPaginationMeta?.totalCount
    : 0;

  const [sessionsPaginationModel, setSessionsPaginationModel] =
    useState<TableState>({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sortModel: [],
    });
  const {
    adminChartsSessions: getAdminChartsSessions,
    isLoadingAdminChartsSessions,
    reqAdminChartsSessions,
    getPage: getSessionsPage,
    getMeta: getSessionsMeta,
  } = useAdminChartsSessions({
    getSilentlyUrl: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
    isPaginated: true,
    pageLimit: sessionsPaginationModel.pageSize,
    queryParams: {
      ...(isRootDomain && { organizationSlug }),
      page: sessionsPaginationModel.page + 1,
      programSlug,
      reportName: 'sessions',
      sortModel: sessionsPaginationModel.sortModel,
    },
    forceRefresh: true,
  });
  const sessionsMeta = getSessionsMeta();
  const sessionsPaginationMeta = sessionsMeta?.pagination;
  const sessionsTotalRows = sessionsPaginationMeta?.isPaginated
    ? sessionsPaginationMeta?.totalCount
    : 0;

  const [skillsSoughtPaginationModel, setSkillsSoughtPaginationModel] =
    useState<TableState>({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sortModel: [],
    });
  const {
    adminChartsSkillsSought: getAdminChartsSkillsSought,
    isLoadingAdminChartsSkillsSought,
    reqAdminChartsSkillsSought,
    getPage: getSkillsSoughtPage,
    getMeta: getSkillsSoughtMeta,
  } = useAdminChartsSkillsSought({
    forceRefresh: true,
    getSilentlyUrl: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
    pageLimit: skillsSoughtPaginationModel.pageSize,
    isPaginated: true,
    queryParams: {
      ...(isRootDomain && { organizationSlug }),
      programSlug,
      reportName: 'skills-sought',
      sortModel: skillsSoughtPaginationModel.sortModel,
    },
  });
  const skillsSoughtMeta = getSkillsSoughtMeta();
  const skillsSoughtPaginationMeta = skillsSoughtMeta?.pagination;
  const skillsSoughtTotalRows = skillsSoughtPaginationMeta?.isPaginated
    ? skillsSoughtPaginationMeta?.totalCount
    : 0;

  const [skillsOfferedPaginationModel, setSkillsOfferedPaginationModel] =
    useState<TableState>({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sortModel: [],
    });
  const {
    adminChartsSkillsOffered: getAdminChartsSkillsOffered,
    isLoadingAdminChartsSkillsOffered,
    reqAdminChartsSkillsOffered,
    getPage: getSkillsOfferedPage,
    getMeta: getSkillsOfferedMeta,
  } = useAdminChartsSkillsOffered({
    getSilentlyUrl: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
    pageLimit: skillsOfferedPaginationModel.pageSize,
    isPaginated: true,
    forceRefresh: true,
    queryParams: {
      ...(isRootDomain && { organizationSlug }),
      programSlug,
      page: skillsOfferedPaginationModel.page + 1,
      reportName: 'skills-offered',
      sortModel: skillsOfferedPaginationModel.sortModel,
    },
  });
  const skillsOfferedMeta = getSkillsOfferedMeta();
  const skillsOfferedPaginationMeta = skillsOfferedMeta?.pagination;
  const skillsOfferedTotalRows = skillsOfferedPaginationMeta?.isPaginated
    ? skillsOfferedPaginationMeta?.totalCount
    : 0;

  const sanityProgram = getProgram();

  const toolbar = useCallback(
    ({
      fileName,
      headers,
      reportName,
    }: {
      fileName: string;
      headers: string[];
      reportName: string;
    }) =>
      CustomToolbar({
        fileName,
        headers,
        url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
        params: {
          programSlug,
          reportName,
        },
        accessToken,
        onExpiredAccessToken: getAccessToken,
      }),
    [accessToken, getAccessToken, isRootDomain, programSlug],
  );

  if (!sanityProgram) {
    return <></>;
  }

  const isLoading =
    isLoadingAuth ||
    !isAuthenticated ||
    !isAuthorized ||
    isLoadingAdminChartsProgramGuideProfiles() ||
    isLoadingAdminChartsProgramTraineeProfiles() ||
    isLoadingAdminChartsProgramActiveRelationships() ||
    isLoadingAdminChartsProgramConcludedRelationships() ||
    isLoadingAdminChartsProgramGoals() ||
    isLoadingAdminChartsSessions() ||
    isLoadingAdminChartsSkillsSought() ||
    isLoadingAdminChartsSkillsOffered() ||
    isLoadingAdminKPIsProgram() ||
    isLoadingSanityProgram();

  function getUpdatedAt(): string | undefined {
    try {
      const now = new Date();
      const localeData = Intl.DateTimeFormat().resolvedOptions();

      const updatedAtString = `Updated today at ${now.toLocaleTimeString(
        localeData.locale,
        {
          hour12: true,
        },
      )} (${getTimezoneAbbreviation(localeData.timeZone)})`;

      return updatedAtString;
    } catch (err: unknown) {
      return undefined;
    }
  }

  function refresh() {
    reqAdminKPIsProgram({
      url: isRootDomain ? `/superadmin/kpis` : `/admin/kpis`,
      params: {
        ...(isRootDomain && { organizationSlug }),
        programSlug,
        kpiName: 'program',
      },
    });
    reqAdminChartsProgramGuideProfiles({
      url: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
      params: {
        ...(isRootDomain && { organizationSlug }),
        programSlug,
        reportName: 'program-guide-profiles',
        pageLimit: programGuidePaginationModel.pageSize,
        page: programGuidePaginationModel.page + 1,
        sortModel: programGuidePaginationModel.sortModel,
      },
    });
    reqAdminChartsProgramTraineeProfiles({
      url: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
      params: {
        ...(isRootDomain && { organizationSlug }),
        programSlug,
        reportName: 'program-trainee-profiles',
        pageLimit: programTraineePaginationModel.pageSize,
        page: programTraineePaginationModel.page + 1,
        sortModel: programTraineePaginationModel.sortModel,
      },
    });
    reqAdminChartsProgramActiveRelationships({
      url: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
      params: {
        ...(isRootDomain && { organizationSlug }),
        programSlug,
        reportName: 'program-active-relationships',
        pageLimit: programActiveRelationshipsPaginationModel.pageSize,
        page: programActiveRelationshipsPaginationModel.page + 1,
        sortModel: programActiveRelationshipsPaginationModel.sortModel,
      },
    });
    reqAdminChartsProgramConcludedRelationships({
      url: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
      params: {
        ...(isRootDomain && { organizationSlug }),
        programSlug,
        reportName: 'program-concluded-relationships',
        pageLimit: programConcludedRelationshipsPaginationModel.pageSize,
        page: programConcludedRelationshipsPaginationModel.page + 1,
        sortModel: programConcludedRelationshipsPaginationModel.sortModel,
      },
    });
    reqAdminChartsProgramGoals({
      url: isRootDomain ? `/superadmin/charts` : `/admin/charts`,
      params: {
        ...(isRootDomain && { organizationSlug }),
        programSlug,
        reportName: 'program-goals',
        pageLimit: programGoalsPaginationModel.pageSize,
        page: programGoalsPaginationModel.page + 1,
        sortModel: programGoalsPaginationModel.sortModel,
      },
    });
    reqAdminChartsSessions({
      url: isRootDomain
        ? `/superadmin/charts`
        : `/admin/charts?programSlug=${programSlug}&reportName=program-sessions`,
      params: {
        ...(isRootDomain && { organizationSlug }),
        programSlug,
        reportName: 'sessions',
        pageLimit: sessionsPaginationModel.pageSize,
        page: sessionsPaginationModel.page + 1,
        sortModel: sessionsPaginationModel.sortModel,
      },
    });
    reqAdminChartsSkillsSought({
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        reportName: 'skills-sought',
        pageLimit: skillsSoughtPaginationModel.pageSize,
        page: skillsSoughtPaginationModel.page + 1,
        sortModel: skillsSoughtPaginationModel.sortModel,
      },
    });
    reqAdminChartsSkillsOffered({
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        reportName: 'skills-offered',
        pageLimit: skillsOfferedPaginationModel.pageSize,
        page: skillsOfferedPaginationModel.page + 1,
        sortModel: skillsOfferedPaginationModel.sortModel,
      },
    });
    getUpdatedAt();
  }

  const [adminKPIsProgram] = getAdminKPIsProgram();

  const keyStats = [
    {
      label: 'Members',
      toolTipMessage: 'Number of users registered',
      value: adminKPIsProgram?.memberCount ?? 0,
      key: 'users',
      description: 'users',
    },
    {
      label: `${guideOrganizationLabel} memberships`,
      toolTipMessage: `Number of users that are ${guideOrganizationLabelPluralized}`,
      value: adminKPIsProgram?.guideMemberships ?? 0,
      key: 'guideMemberships',
      percentageNumber:
        adminKPIsProgram?.guideMemberships / adminKPIsProgram?.memberCount,
      description: `${Math.ceil(
        (adminKPIsProgram?.guideMemberships / adminKPIsProgram?.memberCount) *
          100,
      )}% of users are ${guideOrganizationLabelPluralized}`,
      ref: 'guideTable',
    },
    {
      label: `${traineeOrganizationLabel} memberships`,
      toolTipMessage: `Number of users that are ${traineeOrganizationLabelPluralized}`,
      value: adminKPIsProgram?.traineeMemberships ?? 0,
      key: 'traineeMemberships',
      percentageNumber:
        adminKPIsProgram?.traineeMemberships / adminKPIsProgram?.memberCount,
      description: `${Math.ceil(
        (adminKPIsProgram?.traineeMemberships / adminKPIsProgram?.memberCount) *
          100,
      )}% of users are ${traineeOrganizationLabelPluralized}`,
      ref: 'traineeTable',
    },
    {
      label: 'Relationships',
      toolTipMessage: 'Number of matches',
      value: adminKPIsProgram?.relationshipCount ?? 0,
      key: 'relationships',
      description: 'relationships',
    },

    {
      label: 'Active relationships',
      value: adminKPIsProgram?.activeRelationshipCount ?? 0,
      key: 'activeRelationships',
      toolTipMessage: 'Number of active relationships in your organization',
      percentageNumber:
        adminKPIsProgram?.activeRelationshipCount &&
        adminKPIsProgram?.relationshipCount
          ? adminKPIsProgram?.activeRelationshipCount /
            adminKPIsProgram?.relationshipCount
          : 0,
      description:
        adminKPIsProgram?.activeRelationshipCount &&
        adminKPIsProgram?.relationshipCount
          ? `${Math.ceil(
              (adminKPIsProgram?.activeRelationshipCount /
                adminKPIsProgram?.relationshipCount) *
                100,
            )}% of relationships`
          : '',
      ref: 'activeRelationships',
    },
    {
      label: 'Concluded relationships',
      value: adminKPIsProgram?.concludedRelationshipCount ?? 0,
      key: 'concludedRelationships',
      toolTipMessage: 'Number of concluded relationships',
      percentageNumber:
        adminKPIsProgram?.concludedRelationshipCount &&
        adminKPIsProgram?.relationshipCount
          ? adminKPIsProgram?.concludedRelationshipCount /
            adminKPIsProgram?.relationshipCount
          : 0,
      description:
        adminKPIsProgram?.concludedRelationshipCount &&
        adminKPIsProgram?.relationshipCount
          ? `${Math.ceil(
              (adminKPIsProgram?.concludedRelationshipCount /
                adminKPIsProgram?.relationshipCount) *
                100,
            )}% of relationships`
          : '',
      ref: 'concludedRelationships',
    },
    {
      label: 'Sessions',
      toolTipMessage: 'Number of sesions booked',
      value: adminKPIsProgram?.sessionCount ?? 0,
      key: 'sessions',
      description: 'sessions',
      ref: 'sessions',
    },
    {
      label: 'Relationships with more than one session',
      value: adminKPIsProgram?.relationshipsWithMultipleSessions ?? 0,
      key: 'RelationshipsCount',
      percentageNumber:
        adminKPIsProgram?.relationshipsWithMultipleSessions &&
        adminKPIsProgram?.relationshipCount
          ? adminKPIsProgram?.relationshipsWithMultipleSessions /
            adminKPIsProgram?.relationshipCount
          : 0,
      description:
        adminKPIsProgram?.relationshipsWithMultipleSessions &&
        adminKPIsProgram?.relationshipCount
          ? `${Math.ceil(
              (adminKPIsProgram?.relationshipsWithMultipleSessions /
                adminKPIsProgram?.relationshipCount) *
                100,
            )}% of relationships`
          : '',
    },
  ];

  const adminChartsProgramGuideProfiles =
    getAdminChartsProgramGuideProfiles()[0];

  const guideProfileRowsResult = adminChartsProgramGuideProfiles?.rows || [];

  const guideProfileRows = guideProfileRowsResult.map((row) => {
    const { joinedProgramOn, ...rest } = row;

    return {
      joinedProgramOn,
      ...rest,
    };
  });

  const today = new Date().toLocaleDateString();

  const guideProfileColumns: GridColDef[] = [
    { field: 'firstName', headerName: 'First name', minWidth: 100, flex: 0.5 },
    { field: 'lastName', headerName: 'Last name', minWidth: 100, flex: 0.5 },
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 1 },
    {
      field: 'joinedProgramOn',
      headerName: 'Joined program on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    {
      field: 'status',
      minWidth: 150,
      headerName: 'Profile status',
      renderCell: (params) => {
        const getChipColor = () => {
          if (params.row.status === 'Published') return 'success';
          if (params.row.status === 'Unpublished') return 'secondary';
          if (params.row.status === 'Awaiting Approval') return 'warning';
          return 'default';
        };
        const chipColor = getChipColor();
        return (
          <Chip
            label={firstLetterUppercase(params.row.status)}
            size="small"
            color={chipColor}
          />
        );
      },
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
    },
    {
      field: 'concludedRelationships',
      headerName: 'Concluded relationships',
    },
    {
      field: 'totalSessions',
      headerName: 'Total sessions',
    },
  ];

  // Use program trainee profiles result to populate table columns and rows

  const adminChartsProgramTraineeProfiles =
    getAdminChartsProgramTraineeProfiles()[0];

  const traineeProfileRowsResult =
    adminChartsProgramTraineeProfiles?.rows || [];

  const traineeProfileRows = traineeProfileRowsResult.map((row) => {
    const { joinedProgramOn, ...rest } = row;

    return {
      joinedProgramOn,
      ...rest,
    };
  });

  const traineeProfileColumns: GridColDef[] = [
    { field: 'firstName', headerName: 'First name', minWidth: 100, flex: 0.4 },
    { field: 'lastName', headerName: 'Last name', minWidth: 100, flex: 0.4 },
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 0.5 },
    {
      field: 'joinedProgramOn',
      headerName: 'Joined program on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
    },
    {
      field: 'concludedRelationships',
      headerName: 'Concluded relationships',
    },
    {
      field: 'totalSessions',
      headerName: 'Total sessions',
    },
  ];

  // Use program active relationships result to populate table columns and rows

  const adminChartsProgramActiveRelationships =
    getAdminChartsProgramActiveRelationships()[0];

  const activeRelationshipsRowsResult =
    adminChartsProgramActiveRelationships?.rows || [];

  const activeRelationshipRows = activeRelationshipsRowsResult.map((row) => {
    const { createdAt, ...rest } = row;

    return {
      createdAt,
      ...rest,
    };
  });

  const activeRelationshipColumns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    {
      field: 'guideEmail',
      headerName: `${guideOrganizationLabel} email`,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'guideFirstName',
      headerName: `${guideOrganizationLabel} first name`,
      minWidth: 130,
    },
    {
      field: 'guideLastName',
      headerName: `${guideOrganizationLabel} last name`,
      minWidth: 130,
    },
    {
      field: 'traineeEmail',
      headerName: `${traineeOrganizationLabel} email`,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'traineeFirstName',
      headerName: `${traineeOrganizationLabel} first name`,
      minWidth: 130,
    },
    {
      field: 'traineeLastName',
      headerName: `${traineeOrganizationLabel} last name`,
      minWidth: 130,
    },
    {
      field: 'sessionsHeld',
      headerName: 'Sessions held',
    },
    {
      field: 'goalsShared',
      headerName: 'Goals shared',
    },
  ];

  const sanitySkillsLabels = sanityProgram?.registration?.skills?.options;

  const adminChartsSkillsSought = getAdminChartsSkillsSought()[0];

  const skillsSoughtRows = adminChartsSkillsSought?.rows || [];

  const parsedskillsSoughtRows = skillsSoughtRows.map((row, index) => {
    const { fieldSlug, id, ...rest } = row;

    return {
      fieldSlug: sanitySkillsLabels?.find(
        (skill) => skill.id.current === fieldSlug,
      )?.label,
      id: `${id}-${index}`,
      ...rest,
    };
  });

  const skillsSoughtColumns = [
    { field: 'fieldSlug', headerName: 'Skills Sought', flex: 1 },
    { field: 'count', headerName: 'Count', minWidth: 120 },
  ];

  const adminChartsSkillsOffered = getAdminChartsSkillsOffered()[0];

  const skillsOfferedRows = adminChartsSkillsOffered?.rows || [];

  const parsedskillsOfferedRows = skillsOfferedRows.map((row, index) => {
    const { fieldSlug, id, ...rest } = row;

    return {
      fieldSlug: sanitySkillsLabels?.find(
        (skill) => skill.id.current === fieldSlug,
      )?.label,
      id: `${id}-${index}`,
      ...rest,
    };
  });

  const skillsOfferedColumns = [
    {
      field: 'fieldSlug',
      headerName: 'Skills Offered',
      flex: 1,
    },
    { field: 'count', headerName: 'Count', minWidth: 120 },
  ];

  const adminChartsProgramConcludedRelationships =
    getAdminChartsProgramConcludedRelationships()[0];

  const concludedRelationshipRowsResult =
    adminChartsProgramConcludedRelationships?.rows || [];

  const concludedRelationshipRows = concludedRelationshipRowsResult.map(
    (row) => {
      const { concludedAt, createdAt, ...rest } = row;

      return {
        concludedAt,
        createdAt,
        ...rest,
      };
    },
  );

  const concludedRelationshipColumns: GridColDef[] = [
    {
      field: 'concludedAt',
      headerName: 'Concluded on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    {
      field: 'guideEmail',
      headerName: `${guideOrganizationLabel} email`,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'guideFirstName',
      headerName: `${guideOrganizationLabel} first name`,
      minWidth: 130,
    },
    {
      field: 'guideLastName',
      headerName: `${guideOrganizationLabel} last name`,
      minWidth: 130,
    },
    {
      field: 'traineeEmail',
      headerName: `${guideOrganizationLabel} email`,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'traineeFirstName',
      headerName: `${guideOrganizationLabel} first name`,
      minWidth: 130,
    },
    {
      field: 'traineeLastName',
      headerName: `${guideOrganizationLabel} last name`,
      minWidth: 130,
    },
    {
      field: 'sessionsHeld',
      headerName: 'Sessions held',
    },
    {
      field: 'goalsShared',
      headerName: 'Goals shared',
    },
  ];

  // Use program goals result to populate table columns and rows

  const adminChartsProgramGoals = getAdminChartsProgramGoals()[0];

  const goalsRowsResult = adminChartsProgramGoals?.rows || [];

  const goalsRows = goalsRowsResult.map((row) => {
    const { createdAt, updatedAt, category, status, ...rest } = row;

    const sanityGoalCategories =
      sanityOrganization?.goal_categories.goal_categories;
    const parsedStatus = () => {
      switch (status) {
        case 'on_track':
          return 'On Track';
        case 'off_track':
          return 'Off Track';
        case 'on_hold':
          return 'On Hold';
        case 'completed':
          return 'Completed';
        default:
          return status;
      }
    };
    return {
      createdAt: createdAt,
      updatedAt: updatedAt,
      category: sanityGoalCategories?.find(
        (goal) => goal.goal_category_slug.current === category,
      )?.goal_category_name,
      status: parsedStatus(),
      ...rest,
    };
  });

  const goalsColumns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    { field: 'userFirstName', headerName: 'First name', minWidth: 100 },
    { field: 'userLastName', headerName: 'Last name', minWidth: 100 },
    { field: 'category', headerName: 'Goal category', flex: 0.5 },
    { field: 'objective', headerName: 'Goal objective', flex: 1 },
    {
      field: 'status',
      headerName: 'Goal status',
      minWidth: 130,
      renderCell: (params) => {
        const getChipColor = () => {
          if (params.row.status === 'Completed') return 'info';
          if (params.row.status === 'On Hold') return 'warning';
          if (params.row.status === 'Off Track') return 'error';
          return 'success';
        };
        const chipColor = getChipColor();
        return (
          <Chip
            label={firstLetterUppercase(params.row.status)}
            size="small"
            color={chipColor}
          />
        );
      },
    },
  ];

  const adminChartsSessions = getAdminChartsSessions()[0];
  const sessionsRows = adminChartsSessions?.rows || [];

  const parsedSessionRows = sessionsRows.map((row) => {
    const {
      id,
      sessionStart,
      sessionEnd,
      location,
      videoConferencing,
      name,
      duration,
      guideFirstName,
      guideLastName,
      guideEmail,
      traineeFirstName,
      traineeLastName,
      traineeEmail,
    } = row;

    return {
      id,
      sessionStart,
      sessionEnd,
      location,
      videoConferencing,
      name,
      duration: `${duration} minutes`,
      guideFirstName,
      guideLastName,
      guideEmail,
      traineeFirstName,
      traineeLastName,
      traineeEmail,
    };
  });

  const sessionsColumns: GridColDef[] = [
    {
      field: 'sessionStart',
      headerName: 'Sessions start',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    {
      field: 'sessionEnd',
      headerName: 'Session end',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    { field: 'name', headerName: 'Name', minWidth: 200 },
    { field: 'duration', headerName: 'Duration' },
    {
      field: 'guideFirstName',
      headerName: `${guideOrganizationLabel} First Name`,
      minWidth: 130,
    },
    {
      field: 'guideLastName',
      headerName: `${guideOrganizationLabel} Last Name`,
      minWidth: 130,
    },
    {
      field: 'guideEmail',
      headerName: `${guideOrganizationLabel} Email`,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'traineeFirstName',
      headerName: `${traineeOrganizationLabel} First Name`,
      minWidth: 130,
    },
    {
      field: 'traineeLastName',
      headerName: `${traineeOrganizationLabel} Last Name`,
      minWidth: 130,
    },
    {
      field: 'traineeEmail',
      headerName: `${traineeOrganizationLabel} Email`,
      minWidth: 200,
      flex: 0.5,
    },
    { field: 'location', headerName: 'Location' },
    { field: 'videoConferencing', headerName: 'Communication tool' },
  ];

  const relationshipsPieChartData = [
    {
      label: 'Active',
      value: adminKPIsProgram?.activeRelationshipCount,
      color: palette.success.main,
    },
    {
      label: 'Concluded',
      value: adminKPIsProgram?.concludedRelationshipCount,
      color: '#C6D9D5',
    },
  ];

  const membershipsPieChartData = [
    {
      label: `${guideOrganizationLabelPluralized}`,
      value: adminKPIsProgram?.guideMemberships,
      color: palette.warning.main,
    },
    {
      label: `${traineeOrganizationLabelPluralized}`,
      value: adminKPIsProgram?.traineeMemberships,
      color: palette.warning.light,
    },
  ];

  const relationshipsTotal = relationshipsPieChartData
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);

  const getRelatinoshipsArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / relationshipsTotal;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const membershipsTotal = membershipsPieChartData
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);

  const getMembershipsArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / membershipsTotal;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const handleCardClick = (cardName: string) => {
    if (tableRefs[cardName].current) {
      tableRefs[cardName]!.current!.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (isMobile) {
    return (
      <ErrorView
        title="Screen size not supported yet"
        message="We are sorry but this screen size is not supported. Please switch to your desktop or laptop to view the metrics."
        iconVariant="warning"
        buttons={[]}
      />
    );
  }

  return (
    <PageView
      title={`${programTypeName}`}
      subtitles={[`${programName}`]}
      headerElement={
        <Stack direction={'column'} alignItems={'flex-end'} spacing={0}>
          <Button
            variant="textWithIcon"
            startIcon={<Refresh />}
            label="Refresh data"
            loading={isLoading}
            onClick={refresh}
          />
          <Text
            text={getUpdatedAt()}
            variant="subtitle2"
            color={palette.paper.text.disabled}
          />
        </Stack>
      }
      sx={{ backgroundColor: palette.paper.background.primary }}
    >
      <Stack direction="column" width="100%" gap={5}>
        <MuiGrid container direction="row" alignItems="stretch" gap={1}>
          {keyStats.map((stat) => (
            <MuiGrid item md={5.9} lg={3.9} key={stat.key}>
              {isLoadingAdminKPIsProgram() ? (
                <Skeleton
                  variant="rounded"
                  height="114px"
                  sx={{ borderRadius: '8px' }}
                />
              ) : (
                <KeyStatCard
                  label={stat.label}
                  toolTipMessage={stat.toolTipMessage}
                  value={stat.value}
                  description={stat.description}
                  percentageNumber={stat.percentageNumber}
                  cardRef={
                    stat.ref ? () => handleCardClick(stat?.ref) : undefined
                  }
                />
              )}
            </MuiGrid>
          ))}
        </MuiGrid>
        <MuiGrid container rowSpacing={2} direction="row">
          {isLoading ? (
            <Skeleton
              variant="rounded"
              height="258px"
              sx={{ borderRadius: '8px', flex: 1, mr: '12px' }}
            />
          ) : (
            <Card
              title="Relationships"
              sx={{
                height: '300px',
                mr: 3,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
                pr: 1,
              }}
            >
              <Button
                label={'Export'}
                color={'primary'}
                startIcon={<FileDownloadOutlinedIcon />}
                variant="textWithIcon"
                onClick={() =>
                  exportCSVFile({
                    rows: relationshipsPieChartData,
                    fileName: `${organizationSlug}-${programSlug}-relationships-${today}`,
                  })
                }
                sx={{
                  alignSelf: 'flex-end',
                  position: 'absolute',
                  px: '4px',
                  m: 0.5,
                  height: '32px',
                  '&:hover': {
                    borderColor: 'cubic-bezier(0.4, 0, 0.2, 1);',
                  },
                }}
              />
              <PieChart
                series={[
                  {
                    data: relationshipsPieChartData,
                    arcLabel: getRelatinoshipsArcLabel,
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: 'column',
                    position: { vertical: 'middle', horizontal: 'right' },
                    padding: 0,
                  },
                }}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    cursor: 'default',
                    pointerEvents: 'none',
                  },
                }}
              />
            </Card>
          )}
          {isLoading ? (
            <Skeleton
              variant="rounded"
              height="258px"
              sx={{ borderRadius: '8px', flex: 1, ml: '12px' }}
            />
          ) : (
            <Card
              title="Memberships"
              sx={{
                height: '300px',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
                pr: 1,
              }}
            >
              <Button
                label={'Export'}
                color={'primary'}
                startIcon={<FileDownloadOutlinedIcon />}
                variant="textWithIcon"
                onClick={() =>
                  exportCSVFile({
                    rows: membershipsPieChartData,
                    fileName: `${organizationSlug}-${programSlug}-memberships-${today}`,
                  })
                }
                sx={{
                  alignSelf: 'flex-end',
                  position: 'absolute',
                  px: '4px',
                  m: 0.5,
                  height: '32px',
                  '&:hover': {
                    borderColor: 'cubic-bezier(0.4, 0, 0.2, 1);',
                  },
                }}
              />
              <PieChart
                series={[
                  {
                    data: membershipsPieChartData,
                    arcLabel: getMembershipsArcLabel,
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: 'column',
                    position: { vertical: 'middle', horizontal: 'right' },
                    padding: 0,
                  },
                }}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    cursor: 'default',
                    pointerEvents: 'none',
                  },
                }}
              />
            </Card>
          )}
        </MuiGrid>
        <div ref={tableRefs['guideTable']}>
          <MetricsTable
            heading={`${guideOrganizationLabel} profiles`}
            rows={guideProfileRows}
            columns={guideProfileColumns}
            loading={isLoadingAdminChartsProgramGuideProfiles()}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setProgramGuidePaginationModel((c) => ({ ...c, sortModel }));

              reqAdminChartsProgramGuideProfiles({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  programSlug,
                  reportName: 'program-guide-profiles',
                  sortModel,
                  page: programGuidePaginationModel.page + 1,
                  pageLimit: programGuidePaginationModel.pageSize,
                },
              });
            }}
            paginationModel={programGuidePaginationModel}
            paginationMode="server"
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={(model) => {
              setProgramGuidePaginationModel((c) => ({
                ...c,
                ...model,
              }));
              getAdminChartsProgramGuideProfilesPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  programSlug,
                  pageLimit: model.pageSize,
                  reportName: 'program-guide-profiles',
                  sortModel: programGuidePaginationModel.sortModel,
                },
              });
            }}
            rowCount={programGuideTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-${programSlug}-${guideOrganizationLabel?.toLocaleLowerCase()}-profiles-${today}`,
                headers: guideProfileColumns.map((column) => column.field),
                reportName: 'program-guide-profiles',
              })
            }
          />
        </div>
        <div ref={tableRefs['traineeTable']}>
          <MetricsTable
            heading={`${traineeOrganizationLabel} profiles`}
            rows={traineeProfileRows}
            columns={traineeProfileColumns}
            loading={isLoadingAdminChartsProgramTraineeProfiles()}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setProgramTraineePaginationModel((c) => ({
                ...c,
                sortModel,
              }));
              reqAdminChartsProgramTraineeProfiles({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  programSlug,
                  reportName: 'program-trainee-profiles',
                  sortModel,
                  page: programTraineePaginationModel.page + 1,
                  pageLimit: programTraineePaginationModel.pageSize,
                },
              });
            }}
            paginationModel={programTraineePaginationModel}
            paginationMode="server"
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={(model) => {
              setProgramTraineePaginationModel((c) => ({
                ...c,
                ...model,
              }));
              getAdminChartsProgramTraineeProfilesPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  programSlug,
                  pageLimit: model.pageSize,
                  reportName: 'program-trainee-profiles',
                  sortModel: programTraineePaginationModel.sortModel,
                },
              });
            }}
            rowCount={programTraineeTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-${programSlug}-${traineeOrganizationLabel?.toLocaleLowerCase()}-profiles-${today}`,
                headers: guideProfileColumns.map((column) => column.field),
                reportName: 'program-trainee-profiles',
              })
            }
          />
        </div>
        <div ref={tableRefs['activeRelationships']}>
          <MetricsTable
            heading={'Active relationships'}
            rows={activeRelationshipRows}
            columns={activeRelationshipColumns}
            loading={isLoadingAdminChartsProgramActiveRelationships()}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setProgramActiveRelationshipsPaginationModel((c) => ({
                ...c,
                sortModel,
              }));
              reqAdminChartsProgramActiveRelationships({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  programSlug,
                  reportName: 'program-active-relationships',
                  sortModel,
                  page: programActiveRelationshipsPaginationModel.page + 1,
                  pageLimit: programActiveRelationshipsPaginationModel.pageSize,
                },
              });
            }}
            paginationModel={programActiveRelationshipsPaginationModel}
            paginationMode="server"
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={(model) => {
              setProgramActiveRelationshipsPaginationModel((c) => ({
                ...c,
                ...model,
              }));
              getAdminChartsProgramActiveRelationshipsProfilesPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  programSlug,
                  pageLimit: model.pageSize,
                  reportName: 'program-active-relationships',
                  sortModel:
                    programActiveRelationshipsPaginationModel.sortModel,
                },
              });
            }}
            rowCount={programActiveRelationshipsTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-${programSlug}-active-relationships-${today}`,
                headers: activeRelationshipColumns.map(
                  (column) => column.field,
                ),
                reportName: 'program-active-relationships',
              })
            }
          />
        </div>
        <div ref={tableRefs['concludedRelationships']}>
          <MetricsTable
            heading={'Concluded relationships'}
            rows={concludedRelationshipRows}
            columns={concludedRelationshipColumns}
            loading={isLoadingAdminChartsProgramConcludedRelationships()}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setProgramConcludedRelationshipsPaginationModel((c) => ({
                ...c,
                sortModel,
              }));
              reqAdminChartsProgramConcludedRelationships({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  programSlug,
                  reportName: 'program-concluded-relationships',
                  sortModel,
                  page: programConcludedRelationshipsPaginationModel.page + 1,
                  pageLimit:
                    programConcludedRelationshipsPaginationModel.pageSize,
                },
              });
            }}
            paginationModel={programConcludedRelationshipsPaginationModel}
            paginationMode="server"
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={(model) => {
              setProgramConcludedRelationshipsPaginationModel((c) => ({
                ...c,
                ...model,
              }));
              getConcludedRelationshipsPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  programSlug,
                  pageLimit: model.pageSize,
                  reportName: 'program-concluded-relationships',
                  sortModel:
                    programConcludedRelationshipsPaginationModel.sortModel,
                },
              });
            }}
            rowCount={concludedRelationshipsTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-${programSlug}-concluded-relationships-${today}`,
                headers: concludedRelationshipColumns.map(
                  (column) => column.field,
                ),
                reportName: 'program-concluded-relationships',
              })
            }
          />
        </div>
        <MetricsTable
          heading={'Goals shared'}
          rows={goalsRows}
          columns={goalsColumns}
          loading={isLoadingAdminChartsProgramGoals()}
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            setProgramGoalsPaginationModel((c) => ({
              ...c,
              sortModel,
            }));
            reqAdminChartsProgramGoals({
              url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
              params: {
                programSlug,
                reportName: 'program-goals',
                sortModel,
                page: programGoalsPaginationModel.page + 1,
                pageLimit: programGoalsPaginationModel.pageSize,
              },
            });
          }}
          paginationModel={programGoalsPaginationModel}
          paginationMode="server"
          pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
          onPaginationModelChange={(model) => {
            setProgramGoalsPaginationModel((c) => ({
              ...c,
              ...model,
            }));
            getProgramGoalsPage({
              url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
              page: model.page + 1,
              params: {
                programSlug,
                pageLimit: model.pageSize,
                reportName: 'program-goals',
                sortModel: programGoalsPaginationModel.sortModel,
              },
            });
          }}
          rowCount={programGoalsTotalRows}
          toolbar={() =>
            toolbar({
              fileName: `${organizationSlug}-${programSlug}-goals-${today}`,
              headers: goalsColumns.map((column) => column.field),
              reportName: 'program-goals',
            })
          }
        />
        <div ref={tableRefs['sessions']}>
          <MetricsTable
            heading={'Sessions'}
            rows={parsedSessionRows}
            columns={sessionsColumns}
            loading={isLoadingAdminChartsSessions()}
            fileName={`${organizationSlug}-${programSlug}-sessions-${today}`}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setSessionsPaginationModel((c) => ({
                ...c,
                sortModel,
              }));
              reqAdminChartsSessions({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  programSlug,
                  reportName: 'sessions',
                  sortModel,
                  page: sessionsPaginationModel.page + 1,
                  pageLimit: sessionsPaginationModel.pageSize,
                },
              });
            }}
            paginationModel={sessionsPaginationModel}
            paginationMode="server"
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={(model) => {
              setSessionsPaginationModel((c) => ({
                ...c,
                ...model,
              }));
              getSessionsPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  programSlug,
                  pageLimit: model.pageSize,
                  reportName: 'sessions',
                  sortModel: sessionsPaginationModel.sortModel,
                },
              });
            }}
            rowCount={sessionsTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-${programSlug}-sessions-${today}`,
                headers: sessionsColumns.map((column) => column.field),
                reportName: 'sessions',
              })
            }
          />
        </div>
        <Stack
          direction="row"
          justifyContent="space-between"
          display={'flex'}
          spacing={4}
        >
          <MetricsTable
            heading={`Skills Sought by ${traineeOrganizationLabelPluralized}`}
            rows={parsedskillsSoughtRows}
            columns={skillsSoughtColumns}
            loading={isLoadingAdminChartsSkillsSought()}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setSkillsSoughtPaginationModel((c) => ({
                ...c,
                sortModel,
              }));
              reqAdminChartsSkillsSought({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  programSlug,
                  reportName: 'skills-sought',
                  sortModel,
                  page: skillsSoughtPaginationModel.page + 1,
                  pageLimit: skillsSoughtPaginationModel.pageSize,
                },
              });
            }}
            paginationModel={skillsSoughtPaginationModel}
            paginationMode="server"
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={(model) => {
              setSkillsSoughtPaginationModel((c) => ({
                ...c,
                ...model,
              }));
              getSkillsSoughtPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  programSlug,
                  pageLimit: model.pageSize,
                  reportName: 'skills-sought',
                  sortModel: skillsSoughtPaginationModel.sortModel,
                },
              });
            }}
            rowCount={skillsSoughtTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-skills-sought-${today}`,
                headers: skillsSoughtColumns.map((column) => column.field),
                reportName: 'skills-sought',
              })
            }
          />
          <MetricsTable
            heading={`Skills Offered by ${guideOrganizationLabelPluralized}`}
            rows={parsedskillsOfferedRows}
            columns={skillsOfferedColumns}
            loading={isLoadingAdminChartsSkillsOffered()}
            fileName={`${organizationSlug}-skills-offered-${today}`}
            sortingMode="server"
            onSortModelChange={(sortModel) => {
              setSkillsOfferedPaginationModel((c) => ({
                ...c,
                sortModel,
              }));
              reqAdminChartsSkillsOffered({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                params: {
                  programSlug,
                  reportName: 'skills-offered',
                  sortModel,
                  page: skillsOfferedPaginationModel.page + 1,
                  pageLimit: skillsOfferedPaginationModel.pageSize,
                },
              });
            }}
            paginationModel={skillsOfferedPaginationModel}
            paginationMode="server"
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={(model) => {
              setSkillsOfferedPaginationModel((c) => ({
                ...c,
                ...model,
              }));
              getSkillsOfferedPage({
                url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
                page: model.page + 1,
                params: {
                  programSlug,
                  pageLimit: model.pageSize,
                  reportName: 'skills-offered',
                  sortModel: skillsOfferedPaginationModel.sortModel,
                },
              });
            }}
            rowCount={skillsOfferedTotalRows}
            toolbar={() =>
              toolbar({
                fileName: `${organizationSlug}-skills-offered-${today}`,
                headers: skillsOfferedColumns.map((column) => column.field),
                reportName: 'skills-offered',
              })
            }
          />
        </Stack>
      </Stack>
    </PageView>
  );
}
