import { useAuth } from '@guider-global/auth-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';

import {
  buildSanityImageUrl,
  useSanitySettings,
} from '@guider-global/sanity-hooks';
import { OrganizationIntegration } from '@guider-global/shared-types';
import {
  HubSpotIntegrationModal,
  IntegrationCard,
  Loading,
  NoticeSnackbar,
  NoticeSnackbarProps,
} from '@guider-global/ui';
import { Box, Grid, useTheme } from '@mui/material';
import { useOrganizationIntegrations } from 'hooks';

import { useState } from 'react';
import { PageView } from 'views';

export function IntegrationsPage() {
  const theme = useTheme();
  const subdomain = getSubDomain();

  const isDemo = subdomain === 'redbridge-partners' || subdomain === 'syntax';

  const { settings } = useSanitySettings({
    getSilently: true,
    localeCode: 'en_GB',
  });

  const hubSpotImageSrc = buildSanityImageUrl({
    source: settings?.static_media?.integrations.hubspot_logo ?? '',
  });

  const { isLoading, isAuthenticated, isAuthorized } = useAuth({});

  const { organizationIntegrations, reqOrganizationIntegrations } =
    useOrganizationIntegrations({
      getSilently: true,
    });

  const hubSpotIntegration = organizationIntegrations().find(
    (integration) => integration.integrationName === 'hubspot',
  );
  const [hubSpotApiToken, setHubSpotApiToken] = useState<string | undefined>();
  const [showHubSpotModal, setShowHubSpotModal] = useState<boolean>(false);

  const [snackbarProps, setSnackbarProps] = useState<NoticeSnackbarProps>({
    show: false,
    message: '',
    variant: 'error',
  });

  async function enableIntegration(data: Partial<OrganizationIntegration>) {
    const { integrationName } = data;
    const result = await reqOrganizationIntegrations({
      method: 'PUT',
      url: `/admin/organizationIntegrations/${integrationName}`,
      data,
    });
    if (result.status !== 'success') {
      console.error({ result });
      const { message } = result;
      setSnackbarProps({
        show: true,
        message:
          message ?? `Unknown error adding ${integrationName} integration`,
        variant: 'error',
      });
    }
  }

  async function deleteIntegration(data: Partial<OrganizationIntegration>) {
    const { integrationName } = data;
    const result = await reqOrganizationIntegrations({
      method: 'DELETE',
      url: `/admin/organizationIntegrations/${integrationName}`,
    });
    if (result.status !== 'success') {
      console.error({ result });
      const { message } = result;
      setSnackbarProps({
        show: true,
        message:
          message ?? `Unknown error deleting ${integrationName} integration`,
        variant: 'error',
      });
    }
    setHubSpotApiToken(undefined);
    setShowHubSpotModal(false);
  }

  if (isLoading || !isAuthenticated || !isAuthorized) {
    return (
      <Loading
        isLoading={true}
        withBackdrop={true}
        backgroundColor={theme.palette.background.paper}
      />
    );
  }

  return (
    <PageView
      title={'Integrations'}
      subtitles={['Manage all of your integrations in one place.']}
    >
      <HubSpotIntegrationModal
        avatarSrc={hubSpotImageSrc}
        isOpen={showHubSpotModal}
        isConnected={Boolean(hubSpotIntegration)}
        // TODO enable during ticket: #1373
        onClickAlertBarActionLabel={() =>
          window.open(
            'https://docs.google.com/document/d/1EneTB-1901qCZlrL0rqaAN3NJOJPQcAIQGl7mZnF7KA/',
            '_blank',
            'noreferrer',
          )
        }
        onClose={() => setShowHubSpotModal(false)}
        // TODO enable during ticket: #1373
        // onClickTest={(event) => {
        //   console.log({ event });
        // }}
        onClickEnable={async (event) => {
          if (!hubSpotApiToken) {
            return;
          }
          enableIntegration({
            integrationName: 'hubspot',
            features: ['profiles:retrieve'],
            apiToken: hubSpotApiToken,
          });
          console.log({ event });
        }}
        onChangeApiToken={(event) => {
          setHubSpotApiToken(event.target.value);
          console.log({ event });
        }}
        onClickDisconnect={async (event) => {
          deleteIntegration({ integrationName: 'hubspot' });
          console.log({ event });
        }}
        apiTokenFieldProps={{ value: hubSpotApiToken }}
      />
      <NoticeSnackbar {...snackbarProps} />
      <Box minHeight="150vh" width="100%">
        <Grid
          container
          spacing={3}
          justifyContent={{ xs: 'center', md: 'left' }}
        >
          <Grid item>
            <IntegrationCard
              heading={'HubSpot'}
              text={
                'Use HubSpot to pre-populate your users data to enable a smoother experience.'
              }
              avatarSrc={hubSpotImageSrc}
              isConnected={Boolean(hubSpotIntegration)}
              onClick={() => {
                if (hubSpotIntegration) setHubSpotApiToken('***************');
                setShowHubSpotModal(true);
              }}
            />
          </Grid>
          {isDemo && (
            <>
              <Grid item>
                <IntegrationCard
                  heading={'Degreed'}
                  text={
                    'Degreed’s learning technology suite helps companies of all sizes — from fast-growing start-ups to global enterprises.'
                  }
                  avatarSrc={
                    'https://s3-alpha-sig.figma.com/img/3f50/ccf3/ae4b97fa789fc1285220f3f941f6826b?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OPWngyijtI4znVlctO8hhQJBV245-VdvwAQOo99rD1ZyPF8aCgM~N-Zc4b8KULTnF62zgfmrFrwa0URnbwesgVoqRz6cVS8lRJ0qqMpUJ6nMoWglu5clo1WcIIPnmwqLIIHD0ReQ~yi8pxQlBxyfeW8~mQQozXkTVidlaSQcgy6gFu81rdJZRyakyDivj0pBy9a9b6RHg1Ocokj8T3PDS0E-lo2~A2Rd1LqHIzQDxOkQlgcMeITQPUkSIZenoOZg0m-K5LbIbZvj5uQr~h5T1EJD1huHGOC~uALXRJr-JgcGFSlOCHt9NoT4ZogCMyEZugbAxet4BUoSS7KGmvTqZQ__'
                  }
                  isConnected
                  onClick={() => {}}
                />
              </Grid>
              <Grid item>
                <IntegrationCard
                  heading={'Enterprise SSO'}
                  text={
                    'Enable all end-users to securely access all of your enterprise’s applications with a single click.'
                  }
                  avatarSrc={
                    'https://s3-alpha-sig.figma.com/img/bcf3/2021/8b765dacba8eb0ae5d269fbd814edecb?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lI9B2~A9l2JwCo0tcXcSHt2WHveYyoF4cSHJ9dn5MSFKOzlZcWR4mXh3VccyES-v2siMfijqhOlxkc~uxC8wCuSnfQ8UNqhcQJQ-epYtZg4MljbXMrk8YyGMbVIMzeEZ8T5QVQ5bj1x8mmlo5S4erVhAHymwbrY6lCAVKo0wasfFzMEYkSvtscnTaqZAUNE6AWaEG1nug3~CeYpJEwwOP1-NH7E71gLg2Tkv0IJWVSXRbWsBmcS7rrfWTCcvNs5-SflIKTKLxtlji-j8EBpV-VBkX306DCFy1uqOwLz2ezZSWWajxCobDvZBCowbBlCAB9HcoooMDSsV-SU6tVj9XA__'
                  }
                  isConnected
                  onClick={() => {}}
                />
              </Grid>
              <Grid item>
                <IntegrationCard
                  beta
                  heading={'MS Teams'}
                  text={
                    'Merge the power of Guider with the familiarity of Microsoft Teams. No switching between apps, no hassle - just pure, streamlined mentorship management.'
                  }
                  avatarSrc={
                    'https://s3-alpha-sig.figma.com/img/3a63/0c26/ad5854aee643e651cc9520e7324d6e50?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=g44xOlymyGMj5lFOsNFB4G3haOr4g-DJ06omW~KPsOfLE2zIIRauAEpgQI9OREgaa8lsA62mPki6X4uOFJsjssvnpXx2yD-AdVLgwu7AtmnKgVTwuEUMYPSCuAJOzwf0DzslZHR6Y7m6SzpN9EMLYwOj87yUMYewXFl428WJ5b2zs7jaPjq9~R~KVAvr8bi0GwF5oEalFF9yA9LFupkww0830jxyRGWDANGpLmnMrbST-W7UeaL1N5enefSc6b0polyb5JR7haNy9~flukLpJLBltjJIZpfDDhpvn53btBIeOLEekfEhtpKrC2j38bL98EQtTEr70wH9TRJkEADIYg__'
                  }
                  onClick={() => {}}
                />
              </Grid>
              <Grid item>
                <IntegrationCard
                  heading={'Slack'}
                  beta
                  text={
                    'Simplify the process of connecting with people and resources, with Slack, across your company to share best practices, find help or advice, and learn new skills.'
                  }
                  avatarSrc={
                    'https://s3-alpha-sig.figma.com/img/9ae8/e122/20fb3def26c2627c5a731bc12b057564?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qnMgBckqlzBgRc77rgJrvd3650ArN0n4Ct9W2r2QQB9JwWLVE3wy5lUu3fie4M6MoxLQlNE5doqjJy15JPJJFSv1F~ndICAFZyWHed1VUftbvpp2UrV~RqHA4Q5qHQD9cF-mwZtSya3UCnq5ZRRMb20JN~xtu51X1TgKAuJx-~xRzHAm5aB2OExz~M9BKb54a2UQk~nOLI9jUVV2myoV9cPzmYec1CoMhOw7ovmH6YR~dUe4yUuJpBRRjyAPmX4LAS3lqrDik2MwsZ~hMac2K2TkfTWiykNXFVnEJQ4qOtLUSIHXOK1JA9SMCwf2CES-Zdic~MQ843UMUjzFi1ZdcA__'
                  }
                  onClick={() => {}}
                />
              </Grid>
              <Grid item>
                <IntegrationCard
                  heading={'CSV Data Import'}
                  text={'Data file import made easy.'}
                  avatarSrc={
                    'https://s3-alpha-sig.figma.com/img/1d2b/b423/8239bdbdcc1e936f47591170280de9cd?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=mvblovN3yyd8gCROVLmzyayqio76bT7GmMN-BZMWNq~P0ezWc6WSckZyIyI8vU0RU29TJS8d2JEzmWwOSszVyT6HEYLHAO14IZEr0t31f-qQGvAJerY2Hi6bNUxpW-n~5F1cNTHx~i0TUJUR7wonZH4jRy22~aZiEVy~3wftgYL2U4tFR-11yGpUVKGn95cEAsDE43nzmXN202iDToXoAjY83Ao5snSFMcSLOC1~vz28yrUKpoYjc2MjySwlYypzAyY1VYAbIUdKCXOo8DXNP4LQjgWBcKQ~2~j5~JFWlmlyFhukR6GO75beMdxor1AcYgQDq~H4O6stKdUXEU9blA__'
                  }
                  onClick={() => {}}
                />
              </Grid>
              <Grid item>
                <IntegrationCard
                  heading={'Docebo'}
                  text={
                    'Extend the value of your Docebo platform with an integration solution that is seamless, flexible, and fit your unique business needs.'
                  }
                  avatarSrc={
                    'https://s3-alpha-sig.figma.com/img/aa36/2684/8015fd748e8f5711d2a9802d7f358cde?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lPPhKpBdqFX3D36cXyib28P-p0WAFLhiyTId-FYEZE~fXPV2B9q3ID1Finx39d4FXDmfQeAbtsjUQaY9PD66a-Zox1WK8PqzcnMIpnE00QD97qC-HDEzBcYc3njXAkw38e2A3ssTI7JLnMMlnXGvNMqFtXH5yEXFneHc-OAA5ZcuxeuCI8xkzokP16NAYflKFtlhJR3TarW27GAicn9dMjux4imKACLpz8pGy6Ov5BvY70VNZ8opp~FgJ0ON9H0etwC8pMcBFJq~cCPda9fnzPBfWNE~jAjBaXLUtRrPUjwmXA4MrAlu07MqXxvWh8OYR9bzNiBI8~nE~zBWjcdRVA__'
                  }
                  onClick={() => {}}
                />
              </Grid>
              <Grid item>
                <IntegrationCard
                  heading={'360 Learning'}
                  text={
                    'Bring the power of the 360Learning platform wherever work takes you with our seamless integration.'
                  }
                  avatarSrc={
                    'https://s3-alpha-sig.figma.com/img/eef4/db40/70a95beac55a82706de2c67b8f20edd8?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bt7apum3d9020OKautt73BkHXboefIaxOGjlMcCXONEh~j~j26m2HfFRYgEy~MBlZswL4WkTiIIczBVlFtBUb3ABUa0gNF8Y1rm7jPv1nWbxszPQebpNDhIsUi3EgnqyNM-SlfvWtnJeySExGE7aSun9zsCmshYxva3DLLQXpoYOHpSgbYcRPPBXpPsA0sDrUNyGFpweOCtw3feZWH7LUdv70JiQXFH1Q3FivaiDpc4qSBs96cB8CrjTgG5-g5OLd8VX3wmNDJaSl~2dzb1LJnV5zKU0vi4EMhn7hj7W2liREpegqXqdIgbK1yGler1LJne8QSniua7Qy9pliZlRrw__'
                  }
                  onClick={() => {}}
                />
              </Grid>
              <Grid item>
                <IntegrationCard
                  heading={'Outlook'}
                  text={
                    'Save time moving between apps and conversations, schedule sessions in one place. Guider makes it easy for mentors and mentees to view shared availability and to book sessions.'
                  }
                  avatarSrc={
                    'https://s3-alpha-sig.figma.com/img/3378/8dce/dd0206eb5828561341857cf1e2d3df5f?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UHZxyn3bJRjagYAmKHCxCrGnRPnT6-MhrikHYcoGEEbc~YNRVXYt8xIjhIhHsX7TTC870HsmgXxeLdxHRZXZZlTT~xXIRTACCk~D~Rh9iVlYCWSA50Hr3PNZiGhqh0p1WJSuqfl4Wfy4LH23b3GDYT6Oq9lVUppgzKAPv2fL5tE5itxNRWWYbnACfK0XUNd44gz0ztURBUv1QrYbr7qhxZwaSlUcxat8E0DEnqn41YumNlr2VQb84uZYk88GTyI93fqPo4Qht6mAnPDSTf51KMpHwyjHud6QKJQOd7g1xSacrtDVxeGtxlIn-JWdfWRw4mul0E79kiqvW7LXGm2sPw__'
                  }
                  onClick={() => {}}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </PageView>
  );
}
