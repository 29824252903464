import { useNavigate, useParams } from 'react-router-dom';
import {
  AlertBox,
  Chip,
  Card,
  Table,
  Loading,
  ErrorView,
} from '@guider-global/ui';
import { useTheme } from '@mui/material';
import { useAdminProgramProfiles, useAdminPrograms } from 'hooks';
import { AvatarBoxView, SubPageView } from 'views';
import { IError } from '@guider-global/shared-types';
import { format } from 'date-fns';
import { useAppContext } from 'context';

export function ProgramViewPage() {
  const { palette } = useTheme();
  const { programSlug } = useParams();
  const { organizationSlug, isRootDomain } = useAppContext();
  const navigate = useNavigate();

  const {
    isLoadingAdminPrograms,
    adminPrograms,
    getErrorsAdminPrograms,
    getCode: getAdminProgramsCode,
    isErrorAdminPrograms,
  } = useAdminPrograms({
    getSilently: true,
    forceRefresh: true,
    queryParams: {
      ...(organizationSlug && { organizationSlug }),
      localeCode: 'en_GB',
    },
    getSilentlyUrl: isRootDomain
      ? `/superadmin/programs/${programSlug}`
      : `/admin/programs/${programSlug}`,
  });

  const {
    isLoadingAdminProgramProfiles,
    adminProgramProfiles,
    getErrorsAdminProgramProfiles,
    getCode: getAdminProgramProfilesCode,
    isErrorAdminProgramProfiles,
  } = useAdminProgramProfiles({
    getSilently: true,
    forceRefresh: true,
    queryParams: {
      ...(!isRootDomain && { organizationSlug }),
    },
    getSilentlyUrl: isRootDomain
      ? `/superadmin/programProfiles/${programSlug}`
      : `/admin/programProfiles/${programSlug}`,
  });

  const programProfiles = adminProgramProfiles();
  const program = adminPrograms()[0];

  if ((!program && !isLoadingAdminPrograms) || isErrorAdminPrograms()) {
    return (
      <ErrorView
        title="Could not find program details"
        message={getErrorsAdminPrograms()
          .flatMap((error: IError) => error.message)
          .join('/n')}
        code={getAdminProgramsCode()}
        iconVariant={'error'}
        buttons={[]}
      />
    );
  }

  if (isErrorAdminProgramProfiles()) {
    return (
      <AlertBox
        title="Could not find program users"
        description={
          getErrorsAdminProgramProfiles()
            .flatMap((error: IError) => error.message)
            .join('/n') + `(${getAdminProgramProfilesCode()})`
        }
        iconVariant={'error'}
      />
    );
  }

  const hasFoundProfiles =
    programProfiles.length > 0 && !isLoadingAdminProgramProfiles();

  return (
    <SubPageView
      title={'Program'}
      subHeading={
        program && !isLoadingAdminPrograms()
          ? program.programName
          : { isLoading: true, variant: 'h2', text: 'Lorem' }
      }
      subtitles={
        program && !isLoadingAdminPrograms()
          ? [`${program.programTypeName}`, program.programDescription ?? '']
          : [
              { isLoading: true, variant: 'body1', text: 'Lorem ipsum dolor' },
              { isLoading: true, variant: 'body1', text: 'Lorem ipsum dolor' },
            ]
      }
      avatarSrc={
        program && !isLoadingAdminPrograms() ? program.programImage : undefined
      }
      onBackButtonClick={() => navigate(-1)}
    >
      <Card>
        {isLoadingAdminProgramProfiles() ? (
          <Loading sx={{ height: '300px' }} />
        ) : null}
        {!hasFoundProfiles && !isLoadingAdminProgramProfiles() ? (
          <AlertBox
            title="No Members Found"
            description="This program has no members yet"
            variant="icon"
            iconVariant="warning"
            sx={{ my: 3 }}
          />
        ) : null}
        {hasFoundProfiles ? (
          <Table
            title="Members"
            columns={[
              {
                field: 'userDetails',
                headerName: 'Member Name',
                minWidth: 400,
                flex: 1,
                valueGetter: (params) => params.row.userDetails.userName,
                renderCell: (params) => {
                  return (
                    <AvatarBoxView
                      size="small"
                      heading={params.row.userDetails.userName}
                      subtitles={[params.row.userDetails.userEmail]}
                      isLoading={
                        isLoadingAdminProgramProfiles() ||
                        isLoadingAdminPrograms()
                      }
                      auth0Picture={params.row.userDetails.userAuth0Picture}
                      blobPicture={params.row.userDetails.userBlobPicture}
                    />
                  );
                },
              },
              {
                field: 'roles',
                headerName: 'Roles',
                minWidth: 200,
                flex: 1.5,
                valueGetter: (params) => params.row.roles[0][1],
                renderCell: (params) => {
                  return params.row.roles?.map(([role, label]: string[]) => (
                    <Chip
                      key={`${role}-chip`}
                      label={label}
                      size="small"
                      color={
                        role === 'guide' ? determineColor(label) : 'secondary'
                      }
                      sx={{ mr: 1 }}
                    />
                  ));
                },
              },
              {
                field: 'joinedProgramOn',
                headerName: 'Joined program on',
                minWidth: 100,
                flex: 1,
                type: 'dateTime',
                valueGetter: ({ value }) => value && new Date(value),
              },
              {
                field: 'lastLoggedIn',
                headerName: 'Last logged in',
                minWidth: 100,
                flex: 1,
                type: 'dateTime',
                valueGetter: ({ value }) => value && new Date(value),
              },
            ]}
            rows={programProfiles.map((profile) => {
              const joinedProgramOn = profile.firstMembershipCreated;
              const lastLoggedIn = profile.lastLoggedIn;

              return {
                id: profile.id,
                userDetails: {
                  userAuth0Picture: profile.auth0Picture,
                  userBlobPicture: profile.picture,
                  userName: profile.displayName,
                  userEmail: profile.email,
                },
                roles: Object.entries(profile.roles),
                joinedProgramOn,
                lastLoggedIn,
              };
            })}
            topBorderColor={palette.primary.main}
            page={0}
            pageSize={10}
            pageSizeOptions={[5, 10, 25]}
            onRowClick={(row) => navigate(`/users/${row.id}`)}
          />
        ) : null}
      </Card>
    </SubPageView>
  );
}

function determineColor(label: string) {
  if (label.includes('Active')) {
    return 'primary';
  }
  if (label.includes('Hidden')) {
    return 'gray';
  }
  if (label.includes('Approval')) {
    return 'warning';
  }
  return 'error';
}
