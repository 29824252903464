import { firstLetterUppercase } from '@guider-global/front-end-utils';
import { IError, IProgramDetails } from '@guider-global/shared-types';
import {
  AlertBox,
  AvatarBox,
  Card,
  Table,
  Chip,
  Loading,
  ErrorView,
} from '@guider-global/ui';
import { useTheme } from '@mui/material';
import { useAppContext } from 'context';
import { useAdminPrograms } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { PageView } from 'views';

export function ProgramListPage() {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { organizationSlug, isRootDomain } = useAppContext();

  const {
    isLoadingAdminPrograms,
    adminPrograms,
    getErrorsAdminPrograms,
    isErrorAdminPrograms,
    getCode,
  } = useAdminPrograms({
    getSilently: true,
    forceRefresh: true,
    queryParams: {
      ...(organizationSlug && { organizationSlug }),
      localeCode: 'en_GB',
    },
    getSilentlyUrl: isRootDomain ? `/superadmin/programs` : `/admin/programs`,
  });

  const isLoading = isLoadingAdminPrograms();

  const programs = adminPrograms();
  const programSlugs = programs.map((program) => program.programSlug);
  const programSlugSet = [...new Set(programSlugs)];
  const filteredPrograms = programSlugSet.map((programSlug) =>
    programs.find((program) => program.programSlug === programSlug),
  ) as IProgramDetails[];

  if ((!programs && !isLoading) || isErrorAdminPrograms()) {
    return (
      <ErrorView
        title="Could not find programs"
        message={getErrorsAdminPrograms()
          .flatMap((error: IError) => error.message)
          .join('/n')}
        code={getCode()}
        iconVariant={'error'}
        buttons={[]}
      />
    );
  }

  const programsCountString = `${filteredPrograms.length} ${
    filteredPrograms.length === 1 ? 'program' : 'programs'
  }`;

  const hasFoundPrograms = programs.length > 0;

  return (
    <PageView
      title="Programs"
      subtitles={isLoading ? ['Loading...'] : [programsCountString]}
    >
      <Card>
        {isLoading ? (
          <Loading isLoading={true} sx={{ height: '300px' }} />
        ) : null}
        {!hasFoundPrograms && !isLoading ? (
          <AlertBox
            title="No Programs Found"
            description="There are no programs yet for this organization"
            variant="icon"
            iconVariant="warning"
          />
        ) : null}

        {hasFoundPrograms && !isLoading ? (
          <Table
            columns={[
              {
                field: 'programOverview',
                headerName: 'Program Name',
                minWidth: 400,
                flex: 1,
                valueGetter: (params) => params.row.programOverview.programName,
                renderCell: (params) => {
                  return (
                    <AvatarBox
                      size="small"
                      avatarSrc={params.row.programOverview.programImage}
                      heading={params.row.programOverview.programName}
                    />
                  );
                },
              },
              {
                field: 'programStatus',
                headerName: 'Program status',
                flex: 1,
                minWidth: 200,
                valueGetter: (params) => params.row.programStatus,
                renderCell: (params) => {
                  const getChipColor = () => {
                    if (params.row.programStatus === 'unlisted') return 'error';
                    if (params.row.programStatus === 'archived') return 'gray';
                    return 'success';
                  };
                  const chipColor = getChipColor();
                  return (
                    <Chip
                      label={firstLetterUppercase(params.row.programStatus)}
                      size="small"
                      color={chipColor}
                    />
                  );
                },
              },
              {
                field: 'programTypeName',
                headerName: 'Program Type',
                minWidth: 200,
              },
            ]}
            rows={filteredPrograms.map((program) => {
              return {
                id: program.programSlug,
                programOverview: {
                  programName: program.programName,
                  programImage: program.programImage,
                },
                programTypeName: program.programTypeName,
                programStatus: program.programStatus,
              };
            })}
            topBorderColor={palette.primary.main}
            page={0}
            pageSize={10}
            pageSizeOptions={[5, 10, 25]}
            onRowClick={(row) => navigate(`${row.id}`)}
          />
        ) : null}
      </Card>
    </PageView>
  );
}
