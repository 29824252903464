import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useAdminUserProfiles } from 'hooks';
import { SubPageView } from 'views';
import { usePictureMulti } from '@guider-global/azure-storage-hooks';
import { ProfileProgramOverviewListContainer } from 'containers';
import { useAppContext } from 'context';
import { ErrorView } from '@guider-global/ui';

export function UsersViewPage() {
  const { userProfileId } = useParams();
  const { organizationSlug, isRootDomain } = useAppContext();

  const navigate: NavigateFunction = useNavigate();

  const {
    adminUserProfiles,
    getErrorsAdminUserProfiles,
    getCode,
    isLoadingAdminUserProfiles,
    isErrorAdminUserProfiles,
  } = useAdminUserProfiles({
    getSilently: true,
    forceRefresh: true,
    getSilentlyUrl: !isRootDomain
      ? `/admin/userProfiles/${userProfileId}`
      : `/superadmin/userProfiles/${userProfileId}`,
    queryParams: {
      ...(organizationSlug && { organizationSlug }),
    },
  });
  const userProfile = adminUserProfiles().find(
    ({ id }) => id === userProfileId,
  );
  const userId = userProfile?.userId;

  const isLoadingUserProfile = isLoadingAdminUserProfiles();

  const userPicture: string | undefined = userProfile?.auth0Picture;
  const userBlobPicture: string | undefined = userProfile?.picture;

  const getPictureParamsList = function () {
    if (isLoadingUserProfile) return [];
    if (!userProfileId) return [];
    if (!userPicture) return [];
    if (!userBlobPicture) return [];
    return [
      {
        pictureId: userProfileId,
        sasTokenApiPath: '/admin/storage',
        containerName: 'pictures',
        pictureUrl: userPicture,
        pictureBlobName: userBlobPicture,
      },
    ];
  };
  const pictureParamsList = getPictureParamsList();
  const { picture: avatarSrcs } = usePictureMulti({ pictureParamsList });

  function getPicture() {
    if (!userProfileId) return undefined;
    return avatarSrcs[userProfileId];
  }
  const picture = getPicture();

  if (isErrorAdminUserProfiles()) {
    return (
      <ErrorView
        title="Could not find user"
        message={getErrorsAdminUserProfiles()
          .flatMap((error) => error.message)
          .join('/n')}
        code={getCode()}
        iconVariant={'error'}
        buttons={[]}
      />
    );
  }
  return (
    <SubPageView
      title="User Profile"
      subHeading={
        userProfile
          ? userProfile.displayName
          : { isLoading: true, text: 'Lorem Ipsum', variant: 'h2' }
      }
      subtitles={
        userProfile
          ? [
              `${userProfile.jobTitle}, ${userProfile.townOrCity} ${userProfile.country} `,
              `Registered on: ${new Date(
                userProfile.createdAt,
              ).toLocaleDateString()}`,
              `Last active: ${new Date(
                userProfile.updatedAt
                  ? userProfile.updatedAt
                  : userProfile.createdAt,
              ).toLocaleDateString()}`,
            ]
          : [
              { isLoading: true, text: 'Lorem ipsum dolor', variant: 'body1' },
              { isLoading: true, text: 'Lorem ipsum dolor', variant: 'body1' },
              { isLoading: true, text: 'Lorem ipsum dolor', variant: 'body1' },
            ]
      }
      avatarSrc={picture}
      onBackButtonClick={() => navigate(-1)}
    >
      {userProfileId && userId ? (
        <ProfileProgramOverviewListContainer profileId={userProfileId} />
      ) : (
        <></>
      )}
    </SubPageView>
  );
}
