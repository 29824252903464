import { useAuth } from '@guider-global/auth-hooks';
import {
  useAdminManualMatch,
  useAdminProgramProfiles,
  useAdminPrograms,
} from 'hooks';
import { useEffect, useState } from 'react';
import { Stack } from '@guider-global/ui';
import { Box } from '@mui/material';
import { getSubDomain } from '@guider-global/front-end-utils';
import { ProgramVariation } from '@guider-global/shared-types';

export type RestPageProps = {
  title: string;
};

/**
 *  @deprecated Demo page. Wiill be deleted.
 */
export function DemoManualMatchPage({ title }: RestPageProps) {
  // Form state
  const [guideProfiles, setGuideProfiles] = useState<string[]>([]);
  const [guideProfileInputValue, setGuideProfileInputValue] =
    useState<string>('');
  const [traineeProfiles, setTraineeProfiles] = useState<string[]>([]);
  const [traineeProfilesInputValue, setTraineeProfilesInputValue] =
    useState<string>('');
  const [organizationSlug, setOrganizationSlug] = useState<string>(
    getSubDomain(),
  );
  const [programSlug, setProgramSlug] = useState<string>();
  const [programTypeSlug, setProgramTypeSlug] = useState<string>();
  const [programVariationTypeSlug, setProgramVariationTypeSlug] =
    useState<ProgramVariation>();

  // Auth hook
  const { isAuthenticated, isAuthorized, isLoading } = useAuth({});

  const {
    isLoadingAdminPrograms,
    adminPrograms,
    getErrorsAdminPrograms,
    getCode: getAdminProgramsCode,
    isErrorAdminPrograms,
  } = useAdminPrograms({
    getSilently: true,
    forceRefresh: true,
    getSilentlyUrl: `/admin/programs?organizationSlug=${organizationSlug}`,
  });

  const {
    isLoadingAdminProgramProfiles,
    adminProgramProfiles,
    reqAdminProgramProfiles,
    getErrorsAdminProgramProfiles,
    getCode: getAdminProgramProfilesCode,
    isErrorAdminProgramProfiles,
  } = useAdminProgramProfiles({
    getSilently: true,
    forceRefresh: true,
    getSilentlyUrl: `/admin/programProfiles/${programSlug}?organizationSlug=${organizationSlug}`,
  });

  const {
    isLoadingAdminManualMatch,
    adminManualMatch,
    reqAdminManualMatch,
    getErrorsAdminManualMatch,
    getCodeAdminManualMatch,
    isErrorAdminManualMatch,
  } = useAdminManualMatch({
    forceRefresh: false,
    getSilently: false,
  });

  const programs = adminPrograms().map(
    ({ programSlug, programName, programTypeName, programVariation }) => {
      return { programSlug, programName, programTypeName, programVariation };
    },
  );
  const profiles = adminProgramProfiles().map(
    ({ id, email, userId, activeRelationshipsCount }) => {
      return { id, email, userId, activeRelationshipsCount };
    },
  );
  const matches = adminManualMatch();

  useEffect(() => {
    const selectedProgram = programs.filter(
      (program) => program.programSlug === programSlug,
    )[0];
    if (selectedProgram) {
      setProgramTypeSlug(selectedProgram.programTypeName);
      setProgramVariationTypeSlug(selectedProgram.programVariation);
    }
  }, [programSlug, programs]);

  const isReadyAuth = !isLoading && isAuthenticated && isAuthorized;

  const isReadyApi =
    !isLoadingAdminPrograms() &&
    !isLoadingAdminProgramProfiles() &&
    !isLoadingAdminManualMatch();

  const isReady = isReadyAuth && isReadyApi;

  if (!isReady) {
    return <>Loading..</>;
  }

  return (
    <>
      <h2>{title}</h2>
      <Stack direction={'row'} spacing={1}>
        <Box>
          Organization Slug
          <input
            type="text"
            value={organizationSlug}
            onChange={(e) => setOrganizationSlug(e.target.value)}
          />
        </Box>
        <Box>
          Program Slug
          <input
            type="text"
            value={programSlug}
            onChange={(e) => setProgramSlug(e.target.value)}
          />
          <button
            onClick={async () => {
              await reqAdminProgramProfiles({
                url: `/admin/programProfiles/${programSlug}?organizationSlug=${organizationSlug}`,
              });
            }}
          >
            Get users
          </button>
        </Box>
        {programSlug && (
          <Box>
            Trainee User(s)
            <input
              type="text"
              onChange={(e) => setTraineeProfilesInputValue(e.target.value)}
            />
            <button
              onClick={() =>
                setTraineeProfiles([
                  ...traineeProfiles,
                  traineeProfilesInputValue,
                ])
              }
            >
              Add
            </button>
            <p>
              <pre>{JSON.stringify(traineeProfiles, null, 2)}</pre>
            </p>
          </Box>
        )}
        {programSlug && (
          <Box>
            Guide User(s)
            <input
              type="text"
              onChange={(e) => setGuideProfileInputValue(e.target.value)}
            />
            <button
              onClick={() =>
                setGuideProfiles([...guideProfiles, guideProfileInputValue])
              }
            >
              Add
            </button>
            <p>
              <pre>{JSON.stringify(guideProfiles, null, 2)}</pre>
            </p>
          </Box>
        )}
        {programSlug && programTypeSlug !== '' && (
          <Box>
            Program Type Slug
            <input
              type="text"
              value={programTypeSlug}
              onChange={(e) => setProgramTypeSlug(e.target.value)}
            />
          </Box>
        )}
        {programSlug && programVariationTypeSlug && (
          <Box>
            <fieldset>
              <legend>Program Variation Type Slug:</legend>
              <Box>
                <input
                  type="radio"
                  id="huey"
                  name="programVariationTypeSlug"
                  value="individual"
                  checked={programVariationTypeSlug === 'individual'}
                  onChange={(e) =>
                    setProgramVariationTypeSlug(
                      e.target.value as ProgramVariation,
                    )
                  }
                />
                <label htmlFor="individual">Individual</label>
              </Box>
              <Box>
                <input
                  type="radio"
                  id="group"
                  name="programVariationTypeSlug"
                  value="group"
                  checked={programVariationTypeSlug === 'group'}
                  onChange={(e) =>
                    setProgramVariationTypeSlug(
                      e.target.value as ProgramVariation,
                    )
                  }
                />
                <label htmlFor="group">Group</label>
              </Box>
            </fieldset>
          </Box>
        )}
        {programSlug && (
          <Box>
            <button
              onClick={async () =>
                await reqAdminManualMatch({
                  method: 'POST',
                  url: `/admin/manualMatch`,
                  // TODO typing needs fixing
                  // @ts-ignore
                  data: {
                    traineeProfiles,
                    guideProfiles,
                    organizationSlug,
                    programSlug,
                    programVariationTypeSlug,
                    programTypeSlug,
                  },
                })
              }
            >
              POST /admin/manualMatch
            </button>
          </Box>
        )}
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <Box>
          <h3>Programs State:</h3>
          <p>
            <pre>
              {!isLoadingAdminPrograms() && JSON.stringify(programs, null, 2)}
            </pre>
          </p>
          <h3>Programs Errors ({getAdminProgramsCode()}): </h3>
          <p>
            <pre>
              {isErrorAdminPrograms() &&
                JSON.stringify(getErrorsAdminPrograms(), null, 2)}
            </pre>
          </p>
        </Box>
        <Box>
          <h3>Program Profiles State:</h3>
          <p>
            <pre>
              {!isLoadingAdminProgramProfiles() &&
                JSON.stringify(profiles, null, 2)}
            </pre>
          </p>
          <h3>Program Profiles Errors ({getAdminProgramProfilesCode()}):</h3>
          <p>
            <pre>
              {isErrorAdminProgramProfiles() &&
                JSON.stringify(getErrorsAdminProgramProfiles(), null, 2)}
            </pre>
          </p>
        </Box>
        <Box>
          <h3>Manual Match State:</h3>
          <p>
            <pre>
              {!isLoadingAdminManualMatch() && JSON.stringify(matches, null, 2)}
            </pre>
          </p>
          <h3>Manual Match Errors ({getCodeAdminManualMatch()}):</h3>
          <p>
            <pre>
              {isErrorAdminManualMatch() &&
                JSON.stringify(getErrorsAdminManualMatch(), null, 2)}
            </pre>
          </p>
        </Box>
      </Stack>
    </>
  );
}
